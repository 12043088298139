import { Thead, Th, Text, Card, CardBody, Flex, Table, Tr, Td, Tbody } from "@chakra-ui/react";
import { Claim } from "__generated__/graphql";
import { CheckCircleIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { sortClaimsByIncidentDate, formatDate, formatAsDollar } from "utils";

interface ClaimsDetailProps {
  claims: Claim[];
}
export function ClaimsDetail({ claims }: ClaimsDetailProps) {
  return (
    <Card w="100%" p="16px" overflow="scroll">
      <CardBody p="0">
        <Flex mb="16px" gap="6px" w="100%" alignItems="center" justifyContent="flex-start">
          {claims.length === 0 ? (
            <CheckCircleIcon color="green.400" fontSize="md" />
          ) : (
            <WarningTwoIcon color="red.500" fontSize="md" />
          )}
          <Text fontSize="2xl">
            {claims.length} Claim{claims.length !== 1 ? "s" : ""}
          </Text>
        </Flex>
        <Flex w="100%" justifyContent="space-between" gap="12px">
          <Card w="100%">
            <Table variant="striped">
              <Thead>
                <Tr style={{ textTransform: "uppercase" }}>
                  <Th>date</Th>
                  <Th>state</Th>
                  <Th>sev</Th>
                  <Th>loss</Th>
                  <Th>settlement details</Th>
                  <Th>source</Th>
                </Tr>
              </Thead>

              <Tbody>
                {sortClaimsByIncidentDate(claims)?.map(
                  (
                    { incidentDate, claimState, severity, reason, indemnityPaid, dataSource },
                    index,
                  ) => {
                    return (
                      <Tr key={index}>
                        <Td fontSize="xs" p="8px 16px">
                          {formatDate(incidentDate)}
                        </Td>
                        <Td fontSize="xs" p="8px 16px">
                          {claimState}
                        </Td>
                        <Td fontSize="xs" p="8px 16px">
                          {severity}
                        </Td>
                        <Td fontSize="xs" p="8px 16px">
                          {indemnityPaid && formatAsDollar.format(indemnityPaid || 0)}
                        </Td>
                        <Td fontSize="xs" p="8px 16px">
                          {reason}
                        </Td>
                        <Td fontSize="xs" p="8px 16px">
                          {dataSource}
                        </Td>
                      </Tr>
                    );
                  },
                )}
              </Tbody>
            </Table>
          </Card>
        </Flex>
      </CardBody>
    </Card>
  );
}
