import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import {
  Box,
  Button,
  Link,
  ButtonGroup,
  Center,
  Spinner,
  Divider,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useParams, Link as RRLink } from "react-router-dom";
import {
  NotificationWrapper,
  QuickAuditToggleProps,
  StatusMenu,
  Subnav,
  SubmissionEventDisplay,
  QuoteResultsCard,
  QuoteResults,
} from "components";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { SubmissionUpdateInput, Event } from "__generated__/graphql";
import { RenewalWrapper } from "./RenewalWrapper";
import { useAppetite } from "providers";
import { formatRenewalFlags } from "utils";
import { PriceCheckIcon } from "assets";
import { useStalePricing } from "providers";

interface RenewalProps {
  data: SubmissionUpdateInput;
  srf?: number;
  mrs?: number;
  quickAuditToggle: ReactElement<QuickAuditToggleProps>;
  events?: Event[];
  isChecked: boolean;
  fetchPricingData?: () => void;
  isFetchingPricingData: boolean;
  fetchAndSaveMrs: () => Promise<void>;
  isLoading: boolean;
  calculatedSrfAdjustment: number;
  quoteResults?: QuoteResults;
}

export function Renewal({
  isFetchingPricingData,
  fetchPricingData,
  fetchAndSaveMrs,
  quickAuditToggle,
  mrs,
  srf,
  events,
  data,
  isLoading,
  calculatedSrfAdjustment,
  quoteResults,
}: RenewalProps) {
  const { id } = useParams();
  const appetite = useAppetite();
  const hasStalePricing = useStalePricing();
  const flags = formatRenewalFlags(appetite);

  return (
    <div>
      <Subnav
        leftContent={
          <Flex direction="column">
            <Flex>
              <Link as={RRLink} to={`/insights/${id}`}>
                <Text lineHeight="none" fontWeight="600" color="indigo.500" fontSize="sm">
                  Basic Info
                  <ChevronRightIcon mt="-2px" />
                </Text>
              </Link>
            </Flex>
            <Text lineHeight="none" fontSize="lg">
              Renewal
            </Text>
          </Flex>
        }
        rightContent={
          <Flex alignItems="center" gap="16px">
            <SubmissionEventDisplay events={events || []} />
            <Center height="21px">
              <Divider color="gray.300" orientation="vertical" />
            </Center>
            <ButtonGroup>
              {quickAuditToggle}
              <NotificationWrapper
                label="Pricing data may be incorrect"
                showNotification={hasStalePricing}
              >
                <Button
                  onClick={fetchPricingData}
                  mx="4px"
                  variant="outline"
                  colorScheme="indigo"
                  border="none"
                >
                  <PriceCheckIcon isAnimating={isFetchingPricingData} />
                </Button>
              </NotificationWrapper>
              <StatusMenu status={data?.status || undefined} />
            </ButtonGroup>
          </Flex>
        }
      />
      <Flex w="100%" direction="column" alignItems="center" pr="47px" pt="70px">
        <Box
          m="30px 30px 0px 30px"
          w="100%"
          maxW="1040px"
          minW={{ lg: "930px", md: "700px", sm: "400px" }}
          pl="10px"
          pr="15px"
        >
          {quoteResults && <QuoteResultsCard quoteResults={quoteResults} />}
        </Box>
        {isLoading ? (
          <Spinner />
        ) : data && data?.renewedSubmissionId ? (
          <RenewalWrapper
            calculatedSrfAdjustment={calculatedSrfAdjustment}
            fetchAndSaveMrs={fetchAndSaveMrs}
            flags={flags}
            previousSubmissionId={data?.renewedSubmissionId}
            data={data}
            mrs={mrs}
            srf={srf}
          />
        ) : (
          <Navigate to={`/insights/${id}/model-insights`} replace={true} />
        )}
      </Flex>
    </div>
  );
}
