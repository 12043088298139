import { SubmissionUpdateInput, ProviderUpdateInput } from "__generated__/graphql";
import { ChangeEvent, useCallback, useState } from "react";
import { Button, Flex } from "@chakra-ui/react";
import { UPDATE_PROVIDERS } from "mutations";
import { GET_SUBMISSION } from "queries";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ProviderForm } from "components";

interface ResolveFormProps {
  data: SubmissionUpdateInput;
  provider: ProviderUpdateInput;
  refetchNpiData: (provider: ProviderUpdateInput) => void;
}
export function ResolveForm({ refetchNpiData, provider, data }: ResolveFormProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [updateProviders] = useMutation(UPDATE_PROVIDERS, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    control,
    getValues,
    formState: { errors },
  } = useForm<SubmissionUpdateInput>({ defaultValues: { ...data } });
  const index = data?.providers?.map((p) => p.id).indexOf(provider.id) || 0;
  const onSubmit = useCallback(
    async (submission: SubmissionUpdateInput) => {
      try {
        setIsSaving(true);
        await updateProviders({
          variables: { submissionId: submission.id, providers: submission.providers },
        });
        if (submission?.providers?.[index]) {
          refetchNpiData(submission.providers[index]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsSaving(false);
      }
    },
    [index, refetchNpiData, updateProviders],
  );

  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;
      setValue(name as keyof SubmissionUpdateInput, value);
      onSubmit(getValues());
    },
    [setValue, getValues, onSubmit],
  );

  const address = watch(`providers.${index}.address`);

  return (
    <Flex
      w="100%"
      flexDirection="column"
      alignItems="center"
      borderRadius="3px"
      pb="32px"
      mb="16px"
      onSubmit={handleSubmit(onSubmit)}
    >
      <ProviderForm
        clearErrors={clearErrors}
        provider={provider}
        onChangeSelect={handleSelectChange}
        control={control}
        address={address}
        setValue={setValue}
        errors={errors}
        index={index}
        register={register}
        lengthOfSet={data?.providers?.length || 0}
        saveComponent={
          <Flex w="100%" justifyContent="flex-end">
            <Button
              onClick={() => onSubmit(getValues())}
              w="17%"
              colorScheme="indigo"
              m="8px 15px"
              alignSelf="flex-end"
              textAlign="center"
              isLoading={isSaving}
            >
              Save & Refresh
            </Button>
          </Flex>
        }
      />
    </Flex>
  );
}
