import { Spinner, InputGroup, InputRightElement, InputProps, Input } from "@chakra-ui/react";

interface PercentInputProps extends InputProps {
  isLoading?: boolean;
  isLocked?: boolean;
}

export function PercentInput({ isLocked, isLoading, ...rest }: PercentInputProps) {
  return (
    <InputGroup>
      <InputRightElement mt="5px" pointerEvents="none">
        {isLoading ? <Spinner /> : "%"}
      </InputRightElement>
      <Input {...rest} pl="20px" borderColor={isLocked ? "gray.200" : "indigo.300"} />
    </InputGroup>
  );
}
