import { IoTrendingUp, IoTrendingDown } from "react-icons/io5";
import { GoDash } from "react-icons/go";
import { Flex, Text, Icon } from "@chakra-ui/react";
import { Rolodex } from "components";

export interface TrendProps {
  value: number | string;
  direction: "up" | "down" | "none";
}
export function Trend({ value, direction }: TrendProps) {
  const color = direction === "none" ? "gray.450" : direction === "down" ? "green.500" : "red.500";
  return (
    <Flex>
      <Text fontSize="sm" fontWeight="bold" color={color}>
        {typeof value === "number" ? <Rolodex num={value} /> : value}
      </Text>
      {direction === "up" ? (
        <Icon fontSize="24px" as={IoTrendingUp} mt="3px" color={color} />
      ) : direction === "down" ? (
        <Icon fontSize="24px" as={IoTrendingDown} ml="2px" mt="1px" color={color} />
      ) : (
        <Icon fontSize="24px" as={GoDash} ml="6px" mt="1px" color={color} />
      )}
    </Flex>
  );
}
