import { Flex, Text } from "@chakra-ui/react";
import { WarningTwoIcon, CheckCircleIcon } from "@chakra-ui/icons";

interface CardActionProps {
  message: string;
  variant?: "success" | "warning" | "error";
}

export function CardAction({ message, variant }: CardActionProps) {
  return (
    <Flex w="100%" alignItems="flex-start" gap="8px">
      {variant && variant !== "success" ? (
        <WarningTwoIcon
          mt="6px"
          fontSize="xm"
          color={variant === "error" ? "red.500" : "orange.500"}
        />
      ) : (
        <CheckCircleIcon mt="6px" fontSize="xm" color="green.500" />
      )}
      <Text fontSize="sm" fontWeight="normal" textAlign="left" lineHeight="xxs">
        {message}
      </Text>
    </Flex>
  );
}
