import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  FormErrorMessage,
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Grid,
  GridItem,
  Text,
  IconButton,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Input,
  Badge,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormClearErrors,
} from "react-hook-form";
import { STATES } from "app-constants";
import { WarningIcon, DeleteIcon, ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { AddressOption, AddressInputField, DatePicker } from "components";
import {
  InputMaybe,
  AddressInput,
  SubmissionUpdateInput,
  LimitType,
  Limit,
  EntityUpdateInput,
} from "__generated__/graphql";
import { addressToString, formatLimit, formatErrors } from "utils";

interface EntityFormProps {
  index: number;
  register: UseFormRegister<SubmissionUpdateInput>;
  errors?: FieldErrors<SubmissionUpdateInput>;
  setValue?: UseFormSetValue<SubmissionUpdateInput>;
  address?: InputMaybe<AddressInput>;
  lengthOfSet: number;
  control: Control<SubmissionUpdateInput>;
  deleteEntity: () => void;
  onChangeSelect?: (e: ChangeEvent<HTMLSelectElement>) => void;
  entity?: EntityUpdateInput;
  clearErrors: UseFormClearErrors<SubmissionUpdateInput>;
}
export function EntityForm({
  errors,
  index,
  address,
  setValue,
  register,
  control,
  deleteEntity,
  onChangeSelect,
  entity,
  clearErrors,
}: EntityFormProps) {
  const e = errors?.entities?.[index];
  const { onOpen, isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const [selectedAddress, setSelectedAddress] = useState<AddressOption | null>(() => {
    if (address) {
      return {
        label: addressToString(address),
        value: address,
      };
    }
    return null;
  });

  useEffect(() => {
    if (e) {
      onOpen();
    }
  }, [e, onOpen]);

  useEffect(() => {
    if (selectedAddress === null && address) {
      setSelectedAddress({
        label: addressToString(address),
        value: address,
      });
    }
  }, [selectedAddress, address, setSelectedAddress]);
  useEffect(() => {
    if (!entity?.name) {
      onOpen();
    }
  }, [onOpen, entity]);

  return (
    <>
      <Card
        as={Button}
        onClick={onToggle}
        w="100%"
        aria-label="expand provider review card"
        variant="ghost"
        alignItems="start"
        h="92px"
        m="16px 0px 0px 0px"
        p="10px 20px 20px 20px"
        style={{
          boxShadow:
            "1px 1px 3px 0px rgba(0, 0, 0, 0.10), 1px 1px 3px 0px rgba(0, 0, 0, 0.10) !important",
          display: isOpen ? "none" : "flex",
        }}
        _hover={{ background: "white" }}
      >
        <CardHeader p="0" w="100%">
          <Flex p="0" w="100%" justifyContent="space-between" alignItems="center">
            <Text lineHeight="none" fontSize="3xl" p="0">
              Medical Practice
            </Text>
            <Flex gap="16px">
              {e && <Badge variant="boxy">{formatErrors(e)}</Badge>}
              <Box w="40px" h="40px" pt="6px" pl="2px">
                <ChevronDownIcon fontSize="1.5rem" />
              </Box>
            </Flex>
          </Flex>
        </CardHeader>
      </Card>
      <Card
        style={{
          display: isOpen ? "block" : "none",
        }}
        bgColor="white"
        borderRadius="3px"
        variant="outline"
        pb="15px"
        mt="16px"
      >
        <CardHeader pb="0">
          <Flex justifyContent="space-between" alignItems="center" pb="0px">
            <Text lineHeight="none" fontSize="3xl">
              Medical Practice
            </Text>
            <Flex>
              {e && <Badge variant="boxy">{formatErrors(e)}</Badge>}
              {isOpen && (
                <Button
                  onClick={deleteEntity}
                  variant="solid"
                  bgColor="indigo.100"
                  color="indigo.500"
                  aria-label="Delete medical practice"
                  m="0px"
                  rightIcon={<DeleteIcon fontSize="0.8rem" />}
                >
                  Delete
                </Button>
              )}
              {!isOpen ? (
                <IconButton
                  onClick={onToggle}
                  variant="ghost"
                  aria-label="Expand medical practice"
                  fontSize="1.5rem"
                  m="0px"
                  icon={<ChevronDownIcon />}
                />
              ) : (
                <IconButton
                  onClick={onToggle}
                  variant="ghost"
                  aria-label="Collapse medical practice"
                  fontSize="1.5rem"
                  m="0px"
                  icon={<ChevronUpIcon />}
                />
              )}
            </Flex>
          </Flex>
        </CardHeader>
        <Collapse in={isOpen} animateOpacity style={{ overflow: "visible" }}>
          <CardBody pt="12px" pb="0px">
            <Grid
              templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
              gap="12px"
            ></Grid>
            <Grid
              pt="12px"
              gap="12px"
              templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
            >
              <GridItem colSpan={10}>
                <FormControl isInvalid={!!e?.name?.message}>
                  <FormLabel
                    htmlFor={`entities.${index}.name`}
                    fontSize="md"
                    mb="0px"
                    fontWeight="normal"
                  >
                    Medical Practice Name *
                  </FormLabel>
                  <Input
                    id={`entities.${index}.name`}
                    type="text"
                    {...register(`entities.${index}.name`, {
                      required: "Entity name is required",
                    })}
                  />
                  <FormErrorMessage>
                    <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                    {e?.name?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={2}>
                <Controller
                  rules={{ required: "Retro date is required" }}
                  render={({ fieldState, field }) => (
                    <FormControl isInvalid={Boolean(fieldState.error)}>
                      <FormLabel
                        htmlFor={`entities.${index}.retroDate`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                      >
                        Retro Date *
                      </FormLabel>
                      <DatePicker
                        id={`entities.${index}.retroDate`}
                        invalid={Boolean(fieldState.error)}
                        selected={field?.value || ""}
                        onChange={(date: string) => {
                          clearErrors?.(`entities.${index}.retroDate`);
                          setValue?.(`entities.${index}.retroDate`, date);
                        }}
                      />
                      <FormErrorMessage>
                        <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                        {e?.retroDate?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  control={control}
                  name={`entities.${index}.retroDate`}
                />
              </GridItem>
            </Grid>
            <Grid
              pt="12px"
              gap="12px"
              templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
            >
              <GridItem colSpan={6}>
                <FormControl isInvalid={!!e?.limit?.message}>
                  <FormLabel
                    htmlFor={`entities.${index}.limit`}
                    fontSize="md"
                    mb="0px"
                    fontWeight="normal"
                  >
                    Limit *
                  </FormLabel>
                  <Select
                    id={`entities.${index}.limit`}
                    size="md"
                    m="0px"
                    p="0px"
                    {...register(`entities.${index}.limit`, {
                      required: "Limit is required",
                    })}
                    onChange={(e) => {
                      clearErrors?.(`entities.${index}.limit`);
                      onChangeSelect?.(e) || register(`entities.${index}.limit`).onChange(e);
                    }}
                  >
                    {Object.values(Limit).map((limit) => {
                      return (
                        <option key={limit} value={limit}>
                          {formatLimit(limit)}
                        </option>
                      );
                    })}
                  </Select>
                  <FormErrorMessage>
                    <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                    {e?.limit?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={6}>
                <FormControl isInvalid={!!e?.entityCharges?.message}>
                  <FormLabel
                    htmlFor={`entities.${index}.limit`}
                    fontSize="md"
                    mb="0px"
                    fontWeight="normal"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Medical Practice Charges *
                  </FormLabel>
                  <Select
                    id={`entities.${index}.entityCharges`}
                    size="md"
                    m="0px"
                    p="0px"
                    {...register(`entities.${index}.entityCharges`, {
                      required: "Entity charges selection is required",
                    })}
                    onChange={(e) => {
                      clearErrors?.(`entities.${index}.entityCharges`);
                      onChangeSelect?.(e) ||
                        register(`entities.${index}.entityCharges`).onChange(e);
                    }}
                  >
                    {Object.values(LimitType).map((limitType) => {
                      return (
                        <option key={limitType} value={limitType}>
                          {limitType}
                        </option>
                      );
                    })}
                  </Select>
                  <FormErrorMessage>
                    <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                    {e?.entityCharges?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
            <Grid
              pt="12px"
              gap="12px"
              templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
            >
              <GridItem colSpan={3}>
                <Controller
                  rules={{ required: "Address is required" }}
                  render={({ fieldState }) => (
                    <FormControl isInvalid={Boolean(fieldState.error)}>
                      <FormLabel
                        htmlFor={`entities.${index}.address.streetName`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                      >
                        Address *
                      </FormLabel>
                      <AddressInputField
                        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                          clearErrors(`entities.${index}.address`);
                          setValue?.(
                            `entities.${index}.address.streetName`,
                            e?.target?.value || "",
                          );
                        }}
                        isInvalid={Boolean(fieldState.error)}
                        id={`entities.${index}.address.streetName`}
                        onChange={(option: AddressOption | null) => {
                          clearErrors?.(`entities.${index}.address`);
                          setSelectedAddress(option);
                          setValue?.(`entities.${index}.address`, option?.value || null);
                          setValue?.(`entities.${index}.address.city`, option?.value?.city || "");
                          setValue?.(`entities.${index}.address.state`, option?.value?.state || "");
                          setValue?.(`entities.${index}.address.zip`, option?.value?.zip || "");
                          setValue?.(
                            `entities.${index}.address.secondary`,
                            option?.value?.secondary || "",
                          );
                          setValue?.(
                            `entities.${index}.address.streetName`,
                            option?.value?.streetName || "",
                          );
                        }}
                        value={selectedAddress}
                      />
                      <FormErrorMessage>
                        <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                        {fieldState.error && fieldState.error.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  control={control}
                  name={`entities.${index}.address.streetName`}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl>
                  <FormLabel
                    htmlFor={`entities.${index}.address.secondary`}
                    fontSize="md"
                    mb="0px"
                    fontWeight="normal"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Address Line 2
                  </FormLabel>
                  <Input
                    id={`entities.${index}.address.secondary`}
                    type="text"
                    {...register(`entities.${index}.address.secondary`)}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <Controller
                  name={`entities.${index}.address.city`}
                  rules={{ required: "City is required" }}
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl isInvalid={!!(fieldState.error && fieldState.error.message)}>
                      <FormLabel
                        htmlFor={`entities.${index}.address.city`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        City *
                      </FormLabel>
                      <Input
                        id={`entities.${index}.address.city`}
                        type="text"
                        {...field}
                        value={field?.value || ""}
                      />
                      <FormErrorMessage>
                        <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                        {fieldState.error && fieldState.error.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl isInvalid={!!e?.address?.state?.message}>
                  <FormLabel
                    htmlFor={`entities.${index}.address.state`}
                    fontSize="md"
                    mb="0px"
                    fontWeight="normal"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    State *
                  </FormLabel>
                  <Select
                    id={`entities.${index}.address.state`}
                    size="md"
                    m="0px"
                    p="0px"
                    {...register(`entities.${index}.address.state`, {
                      required: "State is required",
                    })}
                    onChange={
                      onChangeSelect || register(`entities.${index}.address.state`).onChange
                    }
                  >
                    <option></option>
                    {STATES.map((state) => (
                      <option key={state.abbreviation} value={state.abbreviation}>
                        {state.abbreviation}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                    {e?.address?.state?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl isInvalid={!!e?.address?.zip?.message}>
                  <FormLabel
                    htmlFor={`entities.${index}.address.zip`}
                    fontSize="md"
                    mb="0px"
                    fontWeight="normal"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Zip Code *
                  </FormLabel>
                  <Input
                    id={`entities.${index}.address.zip`}
                    type="text"
                    {...register(`entities.${index}.address.zip`, {
                      required: "Zip code is required",
                    })}
                  />
                  <FormErrorMessage>
                    <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                    {e?.address?.zip?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}
