import { ReactNode } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import { SuggestedAction } from "localTypes";
import { CardAction, Datum, RangeDisplay, Rolodex } from "components";
import { AVERAGE_MRS_QUOTE_THRESHOLD, AVERAGE_MRS_QUOTE_THRESHOLD_LARGE } from "app-constants";
import { useAppetite } from "providers";

interface GroupCardSummaryProps {
  hasLargeGroup: boolean;
  averageMRS?: number;
  mrsReadable?: number;
}
export function GroupCardSummary({
  hasLargeGroup,
  mrsReadable,
  averageMRS,
}: GroupCardSummaryProps) {
  const appetite = useAppetite();
  const threshold = hasLargeGroup ? AVERAGE_MRS_QUOTE_THRESHOLD_LARGE : AVERAGE_MRS_QUOTE_THRESHOLD;
  let actionComponent: ReactNode | ReactNode[] = appetite?.messages
    ?.filter((message) => {
      switch (appetite.action) {
        case SuggestedAction.Quote:
          return message.intent === "success";
        case SuggestedAction.Decline:
          return message.intent === "error";
        case SuggestedAction.Review:
          return message.intent === "warning";
        default:
          return true;
      }
    })
    ?.map((message, index) => (
      <CardAction key={index} message={message.text} variant={message.intent} />
    ));

  if (appetite?.action === SuggestedAction.Quote) {
    actionComponent = <CardAction message="Meets All Criteria." variant="success" />;
  }

  let backgroundColor = "gray.100";
  let rangeDisplayColorScheme = "gray";
  switch (appetite?.action) {
    case SuggestedAction.Quote:
      backgroundColor = "green.50";
      rangeDisplayColorScheme = "green";
      break;
    case SuggestedAction.Decline:
      backgroundColor = "red.100";
      rangeDisplayColorScheme = "red";
      break;
    case SuggestedAction.Review:
      backgroundColor = "yellow.100";
      rangeDisplayColorScheme = "yellow";
      break;
    default:
      break;
  }

  return (
    <Flex
      padding="16px"
      backgroundColor={backgroundColor}
      justifyContent={{ lg: "space-between", md: "space-around", base: "space-around" }}
      alignItems="center"
      gap="18px"
      borderRadius="3px"
      direction={{ lg: "row", md: "column", base: "column" }}
      data-cy="groupSummary"
    >
      <Box textAlign="left" w="100%">
        <Datum
          label={
            <Text color="black" fontWeight="bold">
              Average MRS
            </Text>
          }
          value={<Rolodex num={mrsReadable} />}
        />
        <RangeDisplay
          colorScheme={rangeDisplayColorScheme}
          threshold={threshold}
          value={averageMRS}
          min={0}
          max={2}
        />
      </Box>
      <Flex
        w="100%"
        h="100%"
        textAlign="right"
        pl="20px"
        justifyContent="center"
        borderLeft={{ lg: "1px", base: "0px" }}
        borderLeftColor={{ lg: "gray.300", base: "none" }}
        flexDirection="column"
      >
        {actionComponent}
      </Flex>
    </Flex>
  );
}
