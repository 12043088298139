var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import "reflect-metadata";
import { Observable } from "@apollo/client/core";
import { ApolloLink } from "@apollo/client/link/core";
import { Sha256 } from "@aws-crypto/sha256-js";
import { SignatureV4 } from "@smithy/signature-v4";
import { print } from "graphql/language/printer";
import { BatchLink } from "@apollo/client/link/batch";
var AwsHttpBatchLink = /** @class */ (function (_super) {
    __extends(AwsHttpBatchLink, _super);
    function AwsHttpBatchLink(axiosClient, credentialProvider, region, url, batchMax, batchInterval) {
        if (batchMax === void 0) { batchMax = 10; }
        if (batchInterval === void 0) { batchInterval = 20; }
        var _this = _super.call(this) || this;
        _this.httpClient = axiosClient;
        _this.requestSigner = new SignatureV4({
            credentials: credentialProvider,
            region: region,
            service: "execute-api",
            sha256: Sha256,
        });
        _this.region = region;
        _this.url = url;
        _this.batchMax = batchMax;
        _this.batchInterval = batchInterval;
        _this.batchLink = new BatchLink({
            batchMax: _this.batchMax,
            batchInterval: _this.batchInterval,
            batchHandler: _this.batchRequest.bind(_this),
        });
        return _this;
    }
    AwsHttpBatchLink.prototype.request = function (operation) {
        return this.batchLink.request(operation);
    };
    AwsHttpBatchLink.prototype.batchRequest = function (operations) {
        var _this = this;
        return new Observable(function (observer) {
            var _a = new URL(_this.url), hostname = _a.hostname, pathname = _a.pathname;
            var batchedBody = JSON.stringify(operations.map(function (operation) { return ({
                operationName: operation.operationName,
                query: print(operation.query),
                variables: operation.variables,
            }); }));
            var unsigned = {
                method: "POST",
                protocol: "https",
                headers: {
                    accept: "*/*",
                    "Content-Type": "application/json; charset=UTF-8",
                    host: hostname,
                },
                hostname: hostname,
                path: pathname,
                body: batchedBody,
            };
            _this.requestSigner
                .sign(unsigned)
                .then(function (signed) {
                return _this.httpClient.request({
                    method: signed.method,
                    url: _this.url,
                    headers: signed.headers,
                    data: signed.body,
                });
            })
                .then(function (response) {
                var results = response.data.map(function (result) {
                    var _a;
                    return ({
                        data: result.data,
                        errors: (_a = result.errors) !== null && _a !== void 0 ? _a : [],
                    });
                });
                observer.next(results);
                observer.complete();
            })
                .catch(function (err) { return observer.error(err); });
        });
    };
    return AwsHttpBatchLink;
}(ApolloLink));
export { AwsHttpBatchLink };
