/* c8 ignore start */
import { z } from "zod";
export var CreateCustomerResponseSchema = z.object({
    id: z.string(),
    simplifyCustomerId: z.string(),
});
export var CreateQuoteResponseSchema = z.object({
    submissionId: z.string(),
    simplifyQuoteId: z.string(),
    initialPremium: z.number(),
    primaryCustomerId: z.string(),
    entitiesInitialPremium: z.array(z.object({
        id: z.string(),
        initialPremium: z.number(),
    })),
    providersInitialPremium: z.array(z.object({
        id: z.string(),
        initialPremium: z.number(),
    })),
    midlevelsInitialPremium: z.array(z.object({
        id: z.string(),
        initialPremium: z.number(),
    }))
});
export var SunlightApiCustomerCreateResponseSchema = z.object({
    Customer: z.object({
        MyPerson: z.object({
            MyPersonalCustomer: z.object({
                NationalProviderIdentifier: z.string(),
                FirstName: z.string(),
                LastName: z.string(),
            }),
        }),
        CustomerNumber: z.string(),
        CustomerName: z.string(),
    }),
});
export var SunlightApiQuoteCreateResponseSchema = z.object({
    PolicyJSON: z.object({
        FullNumber: z.string(),
        PolicyHolderNumber: z.string(),
        ListofCoverages: z.array(z.object({
            MyOfferedCoverage: z.object({ CoverageName: z.string() }),
            Premium: z.object({ Amount: z.number() }),
            CoverageInsuredItemExternalIdentifier: z.string(),
            ServiceExternalIdentifier: z.string(),
        })),
    }),
    ListOfErrors: z.array(z.string()),
});
export var SunlightApiQuoteActivitiesResponseSchema = z.array(z.object({
    Title: z.string(),
    CRMActivityIdentifier: z.string(),
}));
export var SunlightApiQuoteActivityBinaryContentSchema = z.object({
    GetBinaryContentOfActivityCrm: z.object({
        MyForm: z.object({
            MyGeneratedPDF: z.object({
                Document: z.string(),
                ContentType: z.string(),
                FileName: z.string(),
            }),
        }),
    }),
});
/* c8 ignore stop */
