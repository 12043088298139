import {
  Button,
  Grid,
  GridItem,
  Tag,
  Card,
  CardBody,
  Flex,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  Text,
  CardHeader,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { BoardAction } from "__generated__/graphql";
import { StateLicense } from "localTypes";
import { orderBy } from "lodash";

export interface BoardActionsProps {
  boardActions: BoardAction[];
  stateLicenses: StateLicense[];
}

export function BoardActions({ stateLicenses, boardActions }: BoardActionsProps) {
  return (
    <Flex w="100%" direction="column">
      <Flex w="100%">
        <Card w="100%" mb="16px">
          <CardHeader lineHeight="115%" fontWeight="bold" p="16px 16px 8px">
            States and Licenses
          </CardHeader>
          <CardBody>
            <Flex w="612px" border="1px solid" borderRadius="3px" borderColor="gray.300">
              <Table fontSize="xs" variant="striped" aria-label="States and Licenses">
                <Thead w="100%">
                  <Tr>
                    <Th p="16px 12px" w="15%">
                      State
                    </Th>
                    <Th p="16px 12px" w="25%">
                      License Number
                    </Th>
                    <Th p="16px 12px" w="60%">
                      Link
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {stateLicenses.map(({ state, number, link }) => {
                    return link.map((l, index) => {
                      return (
                        <Tr key={index} bgColor={index % 2 === 0 ? "gray.100" : "white"}>
                          <Td p="16px 12px" w="20%">
                            {state}
                          </Td>
                          <Td p="16px 12px" w="40%">
                            {number}
                          </Td>
                          <Td p="16px 12px" w="40%">
                            {l.name !== "Unknown" ? (
                              <Button
                                rightIcon={<ExternalLinkIcon />}
                                size="xs"
                                color="indigo.500"
                                variant="link"
                                as="a"
                                target="_blank"
                                href={l.url}
                                rel="noreferrer noopener"
                              >
                                {l.name}
                              </Button>
                            ) : (
                              <Text>{l.name}</Text>
                            )}
                          </Td>
                        </Tr>
                      );
                    });
                  })}
                </Tbody>
              </Table>
            </Flex>
            {stateLicenses.length === 0 && (
              <Flex w="100%" p="20px" justifyContent="center">
                <Text fontSize="md">No License Data</Text>
              </Flex>
            )}
          </CardBody>
        </Card>
      </Flex>
      <Flex w="100%">
        <Card w="100%">
          <CardHeader lineHeight="115%" fontWeight="bold" p="16px 16px 8px">
            Board Actions
          </CardHeader>
          <CardBody fontSize="xs">
            <Flex
              w="612px"
              border="1px solid"
              borderRadius="3px"
              borderColor="gray.300"
              direction="column"
            >
              <Grid
                fontWeight="bold"
                textTransform="uppercase"
                color="gray.600"
                whiteSpace="nowrap"
                templateColumns={{
                  base: "1fr",
                  md: "repeat(1, 1fr)",
                  lg: "repeat(12, 1fr)",
                }}
                gap="12px"
              >
                <GridItem p="16px 12px" colSpan={1}>
                  Sev
                </GridItem>
                <GridItem p="16px 12px" colSpan={1}>
                  Date
                </GridItem>
                <GridItem p="16px 12px" colSpan={1}>
                  State
                </GridItem>
                <GridItem p="16px 12px" colSpan={4}>
                  Action
                </GridItem>
                <GridItem p="16px 12px" colSpan={2}>
                  Summary
                </GridItem>
                <GridItem p="16px 12px" colSpan={3}>
                  Data Source
                </GridItem>
              </Grid>
              {orderBy(boardActions, ["orderDate"], ["desc"]).map(
                ({ details, orderDate, reportingEntity, severity, summary, npiSource }, index) => (
                  <Grid
                    key={index}
                    bgColor={index % 2 === 0 ? "gray.100" : "white"}
                    templateColumns={{
                      base: "1fr",
                      md: "repeat(1, 1fr)",
                      lg: "repeat(12, 1fr)",
                    }}
                    gap="12px"
                  >
                    <GridItem p="16px 12px" colSpan={1}>
                      <Tag
                        borderRadius="full"
                        variant="solid"
                        size="sm"
                        w="20px"
                        h="20px"
                        colorScheme={
                          severity === 1
                            ? "red"
                            : severity === 2
                              ? "yellow"
                              : severity === 3
                                ? "green"
                                : "gray"
                        }
                      ></Tag>
                    </GridItem>
                    <GridItem p="16px 12px" colSpan={1}>
                      {orderDate?.split(" ")[0] ?? ""}
                    </GridItem>
                    <GridItem p="16px 12px" colSpan={1}>
                      {reportingEntity}
                    </GridItem>
                    <GridItem p="16px 12px" colSpan={4}>
                      <Text style={{ overflowWrap: "break-word" }}>{summary}</Text>
                    </GridItem>
                    <GridItem p="16px 12px" colSpan={2}>
                      {details}
                    </GridItem>
                    <GridItem p="16px 12px" colSpan={3}>
                      {npiSource === "DHC_BOARD_ACTIONS_TABLE" ||
                      npiSource === "PROCURED_BOARD_ACTION_DATASET"
                        ? "DHC"
                        : "Indigo"}
                    </GridItem>
                  </Grid>
                ),
              )}
            </Flex>
            {boardActions.length === 0 && (
              <Flex w="100%" p="20px" justifyContent="center">
                <Text fontSize="md">No Board Actions</Text>
              </Flex>
            )}
          </CardBody>
        </Card>
      </Flex>
    </Flex>
  );
}
