var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { customAlphabet, urlAlphabet } from "nanoid";
import { format, isValid, parse, parseISO } from "date-fns";
import { StatusType, Limit, Deductible, DeclinationReason, NoteType, } from "__generated__/graphql";
import { UserGroups, InsightDetailType } from "localTypes";
import { STATE_LICENSE_URLS } from "app-constants";
export function convertClaimDateToClaim(claimDate, npi) {
    var splitDate = claimDate.split("-");
    var incidentDate = "".concat(splitDate[1], "/").concat(splitDate[2], "/").concat(splitDate[0]);
    return {
        npi: npi,
        incidentDate: incidentDate,
    };
}
export function sortClaimsByIncidentDate(claims) {
    return __spreadArray([], claims, true).sort(function (a, b) {
        var dateA = parseDateString(a.incidentDate);
        var dateB = parseDateString(b.incidentDate);
        if (!dateA && !dateB)
            return 0;
        if (!dateA)
            return 1;
        if (!dateB)
            return -1;
        return dateB.getTime() - dateA.getTime();
    });
}
export function parseDateString(dateString) {
    if (!dateString)
        return null;
    var _a = dateString.split("/").map(Number), month = _a[0], day = _a[1], year = _a[2];
    return new Date(year, month - 1, day);
}
export function formatDate(dateString) {
    if (!dateString)
        return "";
    if (typeof dateString === "string" &&
        (dateString.includes("ago") || dateString.includes("from now"))) {
        return dateString;
    }
    var isoDate = parseISO(dateString);
    if (isValid(isoDate)) {
        return format(isoDate, "MMM d, yyyy");
    }
    var commonFormats = ["yyyy-MM-dd", "MM/dd/yyyy", "MMM d, yyyy"];
    for (var _i = 0, commonFormats_1 = commonFormats; _i < commonFormats_1.length; _i++) {
        var dateFormat = commonFormats_1[_i];
        var parsedDate = parse(dateString, dateFormat, new Date());
        if (isValid(parsedDate)) {
            return format(parsedDate, "MMM d, yyyy");
        }
    }
    return "";
}
export function formatMrsTrends(modelData) {
    var _a, _b, _c, _d, _e, _f;
    return [
        {
            version: "MRS Trend - 2024",
            value: parseFloat(((_b = (_a = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.ADJUSTED_RELATIVE_SCORE_REPORT) || "-1"),
        },
        {
            version: "MRS Trend - 2023",
            value: parseFloat(((_d = (_c = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.ADJUSTED_RELATIVE_SCORE_2023) || "-1"),
        },
        {
            version: "MRS Trend - 2022",
            value: parseFloat(((_f = (_e = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.ADJUSTED_RELATIVE_SCORE_2022) || "-1"),
        },
    ];
}
export function formatBillingData(modelData) {
    var _a, _b, _c, _d, _e;
    var results = (_a = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _a === void 0 ? void 0 : _a[0];
    return {
        payerData: [
            {
                year: "2023",
                amount: results ? "".concat(results.NON_CMS_PAYOR_TOTAL_PAYMENT_1Y_2023) : "",
            },
            {
                year: "2022",
                amount: results ? "".concat(results.NON_CMS_PAYOR_TOTAL_PAYMENT_1Y_2022) : "",
            },
            {
                year: "2021",
                amount: results ? "".concat(results.NON_CMS_PAYOR_TOTAL_PAYMENT_1Y_2021) : "",
            },
        ],
        hvData: [
            {
                year: "2024",
                amount: "".concat((_c = (_b = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.HV_TOTAL_CLAIM_COUNT_1Y_REPORT) || "",
            },
            {
                year: "2023",
                amount: "".concat((_e = (_d = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.HV_TOTAL_CLAIM_COUNT_1Y_2023) || "",
            },
        ],
    };
}
export function formatUserGroups(groups) {
    if (!groups)
        return [];
    return groups.map(function (group) {
        switch (group) {
            case "Underwriters":
                return UserGroups.Underwriters;
            case "Brokers":
                return UserGroups.Brokers;
            default:
                return UserGroups.Unknown;
        }
    });
}
export function generateId() {
    var gen = customAlphabet(urlAlphabet, 7);
    return gen();
}
export var formatAsDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export function formatDeductible(deductible) {
    switch (deductible) {
        case Deductible.None:
            return "None";
        case Deductible["10K"]:
            return "10K";
        case Deductible["25K"]:
            return "25K";
        case Deductible["50K"]:
            return "50K";
        default:
            return "Unknown";
    }
}
export function formatLimit(limit) {
    switch (limit) {
        case Limit["100K_300K"]:
            return "0.1M/0.3M";
        case Limit["200K_600K"]:
            return "0.2M/0.6M";
        case Limit["250K_750K"]:
            return "0.25M/0.75M";
        case Limit["500K_1500K"]:
            return "0.5M/1.5M";
        case Limit["1M_3M"]:
            return "1M/3M";
        case Limit["1M_4M"]:
            return "1M/4M";
        case Limit["1M_5M"]:
            return "1M/5M";
        case Limit["2M_4M"]:
            return "2M/4M";
        case Limit["2M_5M"]:
            return "2M/5M";
        case Limit["2M_6M"]:
            return "2M/6M";
        case Limit["2_65M_7_95M"]:
            return "2.65M/7.65M";
        default:
            return "Unknown";
    }
}
export function formatDeclinationReason(reason) {
    switch (reason) {
        case DeclinationReason.OtherMultiple:
            return "Other/Multiple - Please Describe";
        case DeclinationReason.TooManyRisks:
            return "Too Many Risks";
        case DeclinationReason.Claims:
            return "Claims";
        case DeclinationReason.MrsScoreTooHigh:
            return "MRS Score Too High";
        case DeclinationReason.SubmittedByAnotherBroker:
            return "Submitted by Another Broker";
        case DeclinationReason.BoardAction:
            return "Board Action";
        case DeclinationReason.PracticeProfile:
            return "Practice Profile";
        case DeclinationReason.VlExposure:
            return "VL Exposure";
        case DeclinationReason.NotEnoughInformation:
            return "Not Enough Information";
        default:
            return "Unknown";
    }
}
export function formatStatusType(status) {
    switch (status) {
        case StatusType.ActuaryReview:
            return "Actuary Review";
        case StatusType.AgencyPending:
            return "Agency Pending";
        case StatusType.DsInput:
            return "DS Input";
        case StatusType.InternalDiscussion:
            return "Internal Discussion";
        case StatusType.NeedBrokerInfo:
            return "Need Broker Info";
        case StatusType.StatePending:
            return "State Pending";
        case StatusType.SystemBug:
            return "System Bug";
        case StatusType.UwAnalyzing:
            return "UW Analyzing";
        case StatusType.Ratwip:
            return "RAT WIP";
        case StatusType.RatOfficial:
            return "RAT Official";
        case StatusType.InQueue:
            return "In Queue";
        case StatusType.Declined:
            return "Declined";
        default:
            return "";
    }
}
export function formatLocationInconsistencies(provider, modelData) {
    var _a, _b, _c, _d;
    var address = provider.address;
    var dsCountyName = ((_b = (_a = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.FIPS_COUNTY_NAME_REPORT) || "";
    var dsStateName = ((_d = (_c = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.STATE_REPORT) || "";
    var declaredCounty = (address === null || address === void 0 ? void 0 : address.countyName) || "";
    var declaredState = (address === null || address === void 0 ? void 0 : address.state) || "";
    return [
        {
            ds: "".concat(dsCountyName, ", ").concat(dsStateName),
            iqvia: "",
            inferred: "",
            declared: "".concat(declaredCounty, ", ").concat(declaredState),
            isConsistent: dsCountyName.toLowerCase() === declaredCounty.toLowerCase() &&
                dsStateName.toLowerCase() === declaredState.toLowerCase(),
        },
    ];
}
export function extractInferredSpecialties(modelData) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (!modelData)
        return [];
    return [
        ((_b = (_a = modelData.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.INFERRED_SPECIALTY_RANK_1_2023) || "",
        ((_d = (_c = modelData.results) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.INFERRED_SPECIALTY_RANK_2_2023) || "",
        ((_f = (_e = modelData.results) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.INFERRED_SPECIALTY_RANK_3_2023) || "",
        ((_h = (_g = modelData.results) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.INFERRED_SPECIALTY_RANK_4_2023) || "",
        ((_k = (_j = modelData.results) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.INFERRED_SPECIALTY_RANK_5_2023) || "",
    ];
}
var specialtyMap = {
    "Otorhinolaryngology-Surgery": [
        "Otorhinolaryngology-Surgery",
        "Plastic Otorhinolaryngology-Surgery",
    ],
    "Plastic Otorhinolaryngology-Surgery": [
        "Otorhinolaryngology-Surgery",
        "Plastic Otorhinolaryngology-Surgery",
    ],
    Otolaryngology: ["Otolaryngology", "Otorhinolaryngology"],
    Otorhinolaryngology: ["Otolaryngology", "Otorhinolaryngology"],
};
export function formatSpecialtyInconsistencies(provider, modelData, isQuickAuditEnabled) {
    var _a, _b, _c;
    var nppesSpecialty = provider.nppesSpecialty, indigoSpecialty = provider.indigoSpecialty, manualIndigoSpecialty = provider.manualIndigoSpecialty;
    var validSpecialty = isQuickAuditEnabled
        ? indigoSpecialty
        : manualIndigoSpecialty || indigoSpecialty;
    var modelRatingSpecialty = (_b = (_a = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.INDIGO_SPECIALTY_REPORT;
    var inferredSpecialties = extractInferredSpecialties(modelData);
    var inferredFlag = false;
    var inferred = inferredSpecialties[0];
    if (modelRatingSpecialty) {
        inferredFlag = inferredSpecialties.includes(modelRatingSpecialty);
        if (inferredFlag) {
            var index = inferredSpecialties.indexOf(modelRatingSpecialty);
            inferred = inferredSpecialties[index];
        }
    }
    var isConsistent = ((_c = specialtyMap[modelRatingSpecialty || ""]) === null || _c === void 0 ? void 0 : _c.includes(validSpecialty || "")) ||
        modelRatingSpecialty === validSpecialty;
    return [
        {
            nppes: nppesSpecialty || "",
            dhc: "",
            inferred: inferred || "",
            declared: validSpecialty || "",
            ds: modelRatingSpecialty || "",
            isConsistent: isConsistent,
        },
    ];
}
export function downloadDocument(doc) {
    var a = document.createElement("a");
    a.href = doc.downloadUrl;
    a.download = doc.key;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(doc.downloadUrl);
}
export function downloadBugReport(bugData) {
    if (bugData.submissionSnapshot.id === undefined) {
        throw new Error("Submission ID is required to download bug report");
    }
    var element = document.createElement("a");
    var file = new Blob([JSON.stringify(bugData, null, 2)], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "".concat(bugData.submissionSnapshot.id, "-bug-report.txt");
    document.body.appendChild(element);
    element.click();
}
export function formatFileSize(bytes, decimals) {
    var _a;
    if (decimals === void 0) { decimals = 2; }
    if (bytes === 0)
        return "0 Bytes";
    var k = 1024;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ["Bytes", "KB", "MB", "GB"];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    var unit = (_a = sizes[i]) !== null && _a !== void 0 ? _a : "GB";
    return "".concat(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), " ").concat(unit);
}
export function addressToString(address) {
    if (!address)
        return "";
    var addressString = "";
    addressString += address.streetName ? "".concat(address.streetName) : "";
    addressString += address.secondary ? " ".concat(address.secondary) : "";
    addressString += address.city ? " ".concat(address.city, ",") : ",";
    addressString += address.state ? " ".concat(address.state) : "";
    addressString += address.zip ? " ".concat(address.zip) : "";
    return addressString.split("  ").join(" ").trim();
}
export function parseCustomerResults(customerResults) {
    return customerResults.map(function (customer) { return ({
        customerId: customer.Result.Customer.CustomerNumber,
        npi: customer.Result.Customer.MyPerson.MyPersonalCustomer.NationalProviderIdentifier,
        firstName: customer.Result.Customer.MyPerson.MyPersonalCustomer.FirstName,
        lastName: customer.Result.Customer.MyPerson.MyPersonalCustomer.LastName,
    }); });
}
export function parsePremiumResults(quoteResults) {
    return quoteResults[0].PolicyJSON.ListofCoverages.map(function (coverage) { return ({
        amount: coverage.Premium.Amount,
        customer: coverage.ServiceExternalIdentifier.split(":")[1],
    }); });
}
export function addPremiumToCustomer(customers, premiums) {
    return customers.map(function (customer) {
        var customerPremiums = premiums.filter(function (premium) { return premium.customer === customer.customerId; });
        return __assign(__assign({}, customer), { premium: customerPremiums.reduce(function (sum, premium) { return sum + premium.amount; }, 0) });
    });
}
export function formatCustomerPremiums(sunlightResponse) {
    var customers = parseCustomerResults(sunlightResponse.customerResults);
    var premiums = parsePremiumResults(sunlightResponse.quoteResults);
    var customersWithPremiums = addPremiumToCustomer(customers, premiums);
    return customersWithPremiums;
}
export function sumPremiums(quoteResults) {
    var _a, _b, _c;
    return (((_c = (_b = (_a = quoteResults === null || quoteResults === void 0 ? void 0 : quoteResults[0]) === null || _a === void 0 ? void 0 : _a.PolicyJSON) === null || _b === void 0 ? void 0 : _b.ListofCoverages) === null || _c === void 0 ? void 0 : _c.reduce(function (sum, coverage) { var _a; return sum + ((_a = coverage.Premium) === null || _a === void 0 ? void 0 : _a.Amount); }, 0)) || 0);
}
export function calcMrsAdjustment(mrs, target) {
    return parseInt(Math.abs(((mrs - target) / mrs) * 100).toFixed(2));
}
export function defaultMrsAdjustment(mrs) {
    if (mrs >= 0.35 && mrs <= 0.5) {
        return 0;
    }
    if (mrs >= 0.372 && mrs <= 1.25) {
        return -8;
    }
    if (mrs > 1.26) {
        return 0;
    }
    if (mrs < 0.372) {
        return calcMrsAdjustment(mrs, 0.35);
    }
}
export function formatStateLicenses(licenses) {
    return licenses.map(function (_a) {
        var state = _a.state, number = _a.number;
        var unknownState = false;
        if (STATE_LICENSE_URLS[state || "unknown"] === undefined) {
            console.warn("No license URL for state: ".concat(state));
            unknownState = true;
        }
        return {
            state: state,
            number: number,
            link: STATE_LICENSE_URLS[unknownState ? "unknown" : state || "unknown"] || {
                name: "",
                url: "",
            },
        };
    });
}
export function countErrors(errors, prevCount, depth) {
    if (prevCount === void 0) { prevCount = 0; }
    if (depth === void 0) { depth = 0; }
    var amountOfErrors = prevCount;
    if (depth > 3)
        return amountOfErrors;
    if (errors) {
        return Object.keys(errors)
            .map(function (k) {
            var _a;
            if ((_a = errors[k]) === null || _a === void 0 ? void 0 : _a.type) {
                return 1;
            }
            else {
                return countErrors(errors[k], amountOfErrors, depth + 1);
            }
        })
            .reduce(function (a, b) { return a + b; }, 0);
    }
    return amountOfErrors;
}
export function formatErrors(errors) {
    var errorCount = countErrors(errors);
    if (errorCount === 1) {
        return "1 Issue";
    }
    else {
        return "".concat(errorCount, " Issues");
    }
}
export var formatDecimal = function (num) {
    if (num === undefined)
        return "00.0";
    var isNegative = num < 0;
    var absNum = Math.abs(num);
    var _a = absNum.toFixed(1).split("."), integerPart = _a[0], decimalPart = _a[1];
    var formattedIntegerPart = integerPart.length < 2 ? integerPart.padStart(2, "0") : integerPart;
    var result = "".concat(formattedIntegerPart, ".").concat(decimalPart);
    return isNegative ? "-".concat(result) : result;
};
export function formatBrokerNote(data) {
    var _a;
    if (data === undefined)
        return undefined;
    var note = {
        id: data.id,
        content: data.notes,
        author: (_a = data.producer) === null || _a === void 0 ? void 0 : _a.email,
        type: NoteType.Broker,
    };
    return note;
}
export function doProviderSpecialtiesMatchManualSpecialties(providers) {
    return providers.every(function (provider) {
        return provider.manualIndigoSpecialty
            ? provider.indigoSpecialty === provider.manualIndigoSpecialty
            : true;
    });
}
export function shouldResetQuickAudit(dirtyFields) {
    var _a, _b, _c, _d;
    if (((_b = (_a = dirtyFields === null || dirtyFields === void 0 ? void 0 : dirtyFields.providers) === null || _a === void 0 ? void 0 : _a.flatMap(function (p) { return Object.keys(p); })) === null || _b === void 0 ? void 0 : _b.includes("indigoSpecialty")) ||
        ((_d = (_c = dirtyFields === null || dirtyFields === void 0 ? void 0 : dirtyFields.providers) === null || _c === void 0 ? void 0 : _c.flatMap(function (p) { return Object.keys(p); })) === null || _d === void 0 ? void 0 : _d.includes("newToPractice"))) {
        return true;
    }
    return false;
}
export function generateFactors(data) {
    var _a;
    var factors = [];
    if (data) {
        var d = (_a = data === null || data === void 0 ? void 0 : data.results) === null || _a === void 0 ? void 0 : _a[0];
        if (d) {
            for (var i = 1; i <= 10; i++) {
                if (i === 1 ||
                    i === 2 ||
                    i === 3 ||
                    i === 4 ||
                    i === 5 ||
                    i === 6 ||
                    i === 7 ||
                    i === 8 ||
                    i === 9 ||
                    i === 10) {
                    factors.push({
                        name: d["POSITIVE_FACTOR_".concat(i)] || "",
                        category: d["POSITIVE_FACTOR_".concat(i, "_CATEGORY")] || "",
                        score: "".concat(d["POSITIVE_FACTOR_".concat(i, "_SCORE")] || 0),
                        value: d["POSITIVE_FACTOR_".concat(i, "_VALUE")] || "",
                    });
                }
            }
        }
    }
    return factors;
}
export function mapFlagMessageToInsightDetail(message) {
    var flagMap = {
        "Check Board Actions": InsightDetailType.BoardActions,
        "Check Claims": InsightDetailType.Claims,
        "Check Inconsistencies": InsightDetailType.Consistency,
        "Check Red Flags": InsightDetailType.WebSearch,
    };
    var detailType = flagMap[message.text];
    if (!detailType) {
        return null;
    }
    return detailType;
}
export function formatRenewalFlags(appetite) {
    var _a;
    if (appetite === null)
        return [];
    var providerFlags = (_a = appetite === null || appetite === void 0 ? void 0 : appetite.providers) === null || _a === void 0 ? void 0 : _a.map(function (pf) {
        return {
            providerId: pf.id,
            flags: pf.messages
                .filter(function (f) { return f.intent !== "success"; })
                .map(mapFlagMessageToInsightDetail)
                .filter(function (f) { return f !== null; }),
        };
    });
    return providerFlags || [];
}
export function getDirtyFields(oldObj, newObj) {
    var isObject = function (obj) {
        return obj !== null && typeof obj === "object" && !Array.isArray(obj);
    };
    var isArray = function (obj) { return Array.isArray(obj); };
    var isEmpty = function (obj) {
        if (!obj)
            return true;
        if (isArray(obj))
            return obj.length === 0 || obj.every(isEmpty);
        if (isObject(obj))
            return Object.keys(obj).length === 0;
        return false;
    };
    var diff = {};
    var pendingIdChanges = {};
    var allKeys = Object.keys(oldObj)
        .concat(Object.keys(newObj))
        .filter(function (value, index, self) { return self.indexOf(value) === index; });
    var _loop_1 = function (key) {
        if (key === "id") {
            pendingIdChanges[key] = newObj[key];
            return "continue";
        }
        var oldVal = oldObj[key];
        var newVal = newObj[key];
        // Handle arrays
        if (isArray(oldVal) && isArray(newVal)) {
            var arrayDiff = oldVal
                .map(function (oldItem, index) {
                var newItem = newVal[index];
                if (isObject(oldItem) && isObject(newItem)) {
                    return getDirtyFields(oldItem, newItem);
                }
                return oldItem !== newItem ? newItem : undefined;
            })
                .filter(function (item) { return item !== undefined; })
                .filter(function (item) { return !isEmpty(item); }); // Remove empty objects/arrays
            if (arrayDiff.length > 0) {
                diff[key] = arrayDiff;
            }
            return "continue";
        }
        // Handle nested objects
        if (isObject(oldVal) && isObject(newVal)) {
            var nestedDiff = getDirtyFields(oldVal, newVal);
            if (Object.keys(nestedDiff).length > 0) {
                diff[key] = nestedDiff;
            }
            return "continue";
        }
        // Handle primitive values
        if (oldVal !== newVal) {
            diff[key] = newVal;
        }
    };
    // First pass: collect all non-id changes
    for (var _i = 0, allKeys_1 = allKeys; _i < allKeys_1.length; _i++) {
        var key = allKeys_1[_i];
        _loop_1(key);
    }
    // Second pass: only include IDs if there are other changes
    if (Object.keys(diff).length > 0) {
        Object.assign(diff, pendingIdChanges);
    }
    return diff;
}
