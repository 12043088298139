import { ChangeEvent } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Select,
  Text,
  Flex,
  Icon,
  Grid,
  GridItem,
  Card,
  CardBody,
  CardHeader,
  useMediaQuery,
} from "@chakra-ui/react";
import { Trend, Rolodex } from "components";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { INDIGO_SPECIALTIES } from "app-constants";
import { GoTriangleDown } from "react-icons/go";
import { FaCircle, FaCheck } from "react-icons/fa6";
import { InsightDetailType } from "localTypes";
import { InputMaybe, ProviderUpdateInput } from "__generated__/graphql";

export type ProviderRenewalFlags = {
  providerId: string;
  flags: InsightDetailType[];
};

export interface ProviderRenewalRowProps {
  isLargerThan900: boolean;
  provider: ProviderRenewal;
  isLoading: boolean;
  isLocked: boolean;
  direction: "up" | "down" | "none";
  handleChangeSpecialty: (e: ChangeEvent<HTMLSelectElement>, providerId: string) => void;
  handleSetSelectedInsightType: (type: InsightDetailType) => void;
  handleSetSelectedProviderId: (providerId: string) => void;
  providerFlags: ProviderRenewalFlags;
}

export function ProviderRenewalRow({
  handleSetSelectedProviderId,
  handleSetSelectedInsightType,
  providerFlags,
  direction,
  handleChangeSpecialty,
  isLoading,
  isLocked,
  provider,
  isLargerThan900,
}: ProviderRenewalRowProps) {
  return (
    <Grid
      templateColumns="repeat(100, 1fr)"
      h="54px"
      _hover={{
        backgroundColor: "indigo.100",
      }}
      borderBottom="1px solid"
      borderColor="gray.200"
      fontSize={isLargerThan900 ? "sm" : "xs"}
    >
      <GridItem pt="12px" pl={2} borderRight="1px solid" borderColor="gray.300" colSpan={20}>
        <Text isTruncated>
          {provider.firstName} {provider.lastName} {provider.suffix}
        </Text>
      </GridItem>
      <GridItem px="8px" pt="6px" borderRight="1px solid" borderColor="gray.300" colSpan={25}>
        <Flex>
          <Select
            icon={
              isLoading ? (
                <Spinner fontSize="1rem" color="indigo.500" />
              ) : isLocked ? (
                <span />
              ) : (
                <GoTriangleDown />
              )
            }
            id="providerSpecialty"
            size="sm"
            mt="6px"
            p="0px"
            fontWeight="bold"
            borderColor={isLocked ? "gray.200" : "indigo.300"}
            onChange={(e) => handleChangeSpecialty(e, provider?.id || "")}
            value={provider?.indigoSpecialty || ""}
          >
            {INDIGO_SPECIALTIES.map((spec) => (
              <option key={spec.value} value={spec.name}>
                {spec.name}
              </option>
            ))}
          </Select>
        </Flex>
      </GridItem>
      <GridItem
        pt="12px"
        borderRight="1px solid"
        borderColor="gray.300"
        colSpan={5}
        textAlign="center"
      >
        {provider.hasEndorsement && <Icon as={FaCheck} color="green.500" />}
      </GridItem>
      <GridItem
        pt="12px"
        borderRight="1px solid"
        borderColor="gray.300"
        colSpan={7}
        textAlign="center"
      >
        <Rolodex num={Math.round((provider?.scheduledRatingFactor || 0) * 100) / 100} />%
      </GridItem>
      <GridItem
        pt="12px"
        borderRight="1px solid"
        borderColor="gray.300"
        colSpan={8}
        textAlign="center"
      >
        {provider.oldMrs}
      </GridItem>
      <GridItem
        pt="12px"
        borderRight="1px solid"
        borderColor="gray.300"
        colSpan={8}
        textAlign="center"
        justifyContent="center"
      >
        <Flex justifyContent="center">
          <Trend value={Math.round((provider?.mrs || 0) * 100) / 100} direction={direction} />
        </Flex>
      </GridItem>
      <GridItem
        pt="12px"
        borderRight="1px solid"
        borderColor="gray.300"
        colSpan={11}
        textAlign="center"
      >
        {provider.oldPricing}
      </GridItem>
      <GridItem
        pt="12px"
        borderRight="1px solid"
        borderColor="gray.300"
        colSpan={11}
        textAlign="center"
      >
        {provider.pricing}
      </GridItem>
      <GridItem pt="16px" colSpan={5} textAlign="center">
        <Flex justifyContent="center">
          <Menu>
            <MenuButton aria-label="Options" disabled={providerFlags.flags.length <= 0}>
              <Flex
                borderRadius="full"
                w="20px"
                h="20px"
                justifyContent="center"
                alignItems="center"
                bg={providerFlags.flags.length > 0 ? "red.500" : "green.500"}
              >
                {providerFlags.flags.length > 0 && (
                  <Text fontSize="xs" mt="-3px" color="white">
                    {providerFlags.flags.length}
                  </Text>
                )}
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  if (provider.id) {
                    handleSetSelectedProviderId(provider.id);
                    handleSetSelectedInsightType(InsightDetailType.Confidence);
                  }
                }}
              >
                <Flex w="100%" gap="12px" alignItems="center">
                  <Text flexGrow="1" fontWeight="bold">
                    See All Details
                  </Text>
                  <ChevronRightIcon color="gray.450" />
                </Flex>
              </MenuItem>
              {providerFlags.flags.map((flag: InsightDetailType, index: number) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    if (provider.id) {
                      handleSetSelectedProviderId(provider.id);
                      handleSetSelectedInsightType(flag);
                    }
                  }}
                >
                  <Flex w="100%" gap="12px" alignItems="center">
                    <Icon as={FaCircle} color="red.500" />
                    <Text fontWeight="bold" flexGrow="1">
                      See {flag}
                    </Text>
                    <ChevronRightIcon color="gray.450" />
                  </Flex>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
      </GridItem>
    </Grid>
  );
}

export type ProviderRenewal = Pick<
  ProviderUpdateInput,
  | "id"
  | "firstName"
  | "lastName"
  | "indigoSpecialty"
  | "scheduledRatingFactor"
  | "mrs"
  | "hasEndorsement"
> & {
  oldMrs?: InputMaybe<number>;
  pricing: string;
  oldPricing: string;
  suffix: string;
};

export interface ProviderRenewalTableProps {
  providers?: ProviderRenewal[];
  isLocked: boolean;
  isLoading: boolean;
  flags: ProviderRenewalFlags[];
  handleChangeSpecialty: (e: ChangeEvent<HTMLSelectElement>, providerId: string) => void;
  handleSetSelectedInsightType: (type: InsightDetailType) => void;
  handleSetSelectedProviderId: (providerId: string) => void;
}
export function ProviderRenewalTable({
  isLocked,
  isLoading,
  handleChangeSpecialty,
  handleSetSelectedProviderId,
  handleSetSelectedInsightType,
  providers,
  flags,
}: ProviderRenewalTableProps) {
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  return (
    <>
      <Card mt="32px" whiteSpace="nowrap" minWidth="700px" overflowX="visible">
        <CardHeader
          p="0"
          borderTopRightRadius="3px"
          borderTopLeftRadius="3px"
          bg="indigo.300"
          color="white"
          fontWeight="bold"
          fontSize={isLargerThan900 ? "sm" : "xs"}
          textAlign="center"
        >
          <Grid templateColumns="repeat(100, 1fr)" h="40px">
            <GridItem
              pt={1}
              borderRight="1px solid"
              borderColor="gray.300"
              colSpan={20}
              alignItems="start"
              textAlign="left"
              pl={2}
            >
              Provider Name
            </GridItem>
            <GridItem
              pt={1}
              pl="8px"
              borderRight="1px solid"
              borderColor="gray.300"
              colSpan={25}
              textAlign="left"
            >
              Indigo Specialty
            </GridItem>
            <GridItem pt={1} borderRight="1px solid" borderColor="gray.300" colSpan={5}>
              Endo
            </GridItem>
            <GridItem pt={1} borderRight="1px solid" borderColor="gray.300" colSpan={7}>
              SRF
            </GridItem>
            <GridItem pt={1} borderRight="1px solid" borderColor="gray.300" colSpan={8}>
              MRS Old
            </GridItem>
            <GridItem pt={1} borderRight="1px solid" borderColor="gray.300" colSpan={8}>
              MRS New
            </GridItem>
            <GridItem pt={1} borderRight="1px solid" borderColor="gray.300" colSpan={11}>
              Pricing Old
            </GridItem>
            <GridItem pt={1} borderRight="1px solid" borderColor="gray.300" colSpan={11}>
              Pricing New
            </GridItem>
            <GridItem textAlign="center" pt={1} colSpan={5}>
              Flag
            </GridItem>
          </Grid>
        </CardHeader>

        <CardBody p="0">
          {providers?.map((provider, index) => {
            const oldMrs = provider.oldMrs || 0;
            const newMrs = provider.mrs || 0;
            const direction =
              oldMrs !== 0 ? (newMrs > oldMrs ? "up" : newMrs < oldMrs ? "down" : "none") : "none";
            const providerFlags =
              flags.find((flag) => flag.providerId === provider.id) ||
              ({
                flags: [] as InsightDetailType[],
              } as ProviderRenewalFlags);
            return (
              <ProviderRenewalRow
                direction={direction}
                handleChangeSpecialty={handleChangeSpecialty}
                handleSetSelectedProviderId={handleSetSelectedProviderId}
                handleSetSelectedInsightType={handleSetSelectedInsightType}
                providerFlags={providerFlags}
                isLargerThan900={isLargerThan900}
                provider={provider}
                key={index}
                isLocked={isLocked}
                isLoading={isLoading}
              />
            );
          })}
        </CardBody>
      </Card>
    </>
  );
}

/*
 */
