var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from "@apollo/client";
export var CALCULATE_INSIGHTS_DATA = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation CalculateInsightsData($submissionId: ID!) {\n    calculateInsightsData(submissionId: $submissionId) {\n      averageMrs\n      averageAdjustedMrs\n      bookedSrf\n      calculatedSrfAdjustment\n      scheduledRatingFactor\n      providers {\n        srfAdjustment\n        localAdjustedPercent\n      }\n    }\n  }\n"], ["\n  mutation CalculateInsightsData($submissionId: ID!) {\n    calculateInsightsData(submissionId: $submissionId) {\n      averageMrs\n      averageAdjustedMrs\n      bookedSrf\n      calculatedSrfAdjustment\n      scheduledRatingFactor\n      providers {\n        srfAdjustment\n        localAdjustedPercent\n      }\n    }\n  }\n"])));
export var SAVE_SUBMISSION = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation PutSubmission($input: SubmissionInput!) {\n    putSubmission(input: $input)\n  }\n"], ["\n  mutation PutSubmission($input: SubmissionInput!) {\n    putSubmission(input: $input)\n  }\n"])));
export var ADD_DECLINATION = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation AddDeclination($submissionId: ID!, $declination: DeclinationInput!) {\n    addDeclination(submissionId: $submissionId, declination: $declination)\n  }\n"], ["\n  mutation AddDeclination($submissionId: ID!, $declination: DeclinationInput!) {\n    addDeclination(submissionId: $submissionId, declination: $declination)\n  }\n"])));
export var UPDATE_PRODUCER = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation UpdateProducer($submissionId: ID!, $producer: ProducerUpdateInput!) {\n    updateProducer(submissionId: $submissionId, producer: $producer)\n  }\n"], ["\n  mutation UpdateProducer($submissionId: ID!, $producer: ProducerUpdateInput!) {\n    updateProducer(submissionId: $submissionId, producer: $producer)\n  }\n"])));
export var UPDATE_QUOTE_PROPERTIES = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  mutation UpdateQuoteProperties(\n    $submissionId: ID!\n    $quoteProperties: QuotePropertiesUpdateInput!\n  ) {\n    updateQuoteProperties(submissionId: $submissionId, quoteProperties: $quoteProperties)\n  }\n"], ["\n  mutation UpdateQuoteProperties(\n    $submissionId: ID!\n    $quoteProperties: QuotePropertiesUpdateInput!\n  ) {\n    updateQuoteProperties(submissionId: $submissionId, quoteProperties: $quoteProperties)\n  }\n"])));
export var UPDATE_MIDLEVELS = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  mutation UpdateMidlevels($submissionId: ID!, $midlevels: [MidlevelUpdateInput!]) {\n    updateMidlevels(submissionId: $submissionId, midlevels: $midlevels)\n  }\n"], ["\n  mutation UpdateMidlevels($submissionId: ID!, $midlevels: [MidlevelUpdateInput!]) {\n    updateMidlevels(submissionId: $submissionId, midlevels: $midlevels)\n  }\n"])));
export var UPDATE_ENTITIES = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  mutation UpdateEntities($submissionId: ID!, $entities: [EntityUpdateInput!]) {\n    updateEntities(submissionId: $submissionId, entities: $entities)\n  }\n"], ["\n  mutation UpdateEntities($submissionId: ID!, $entities: [EntityUpdateInput!]) {\n    updateEntities(submissionId: $submissionId, entities: $entities)\n  }\n"])));
export var UPDATE_PROVIDERS = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  mutation UpdateProviders($submissionId: ID!, $providers: [ProviderUpdateInput!]) {\n    updateProviders(submissionId: $submissionId, providers: $providers)\n  }\n"], ["\n  mutation UpdateProviders($submissionId: ID!, $providers: [ProviderUpdateInput!]) {\n    updateProviders(submissionId: $submissionId, providers: $providers)\n  }\n"])));
export var CREATE_NOTE = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  mutation CreateNote($submissionId: ID!, $note: NoteInput!) {\n    createNote(submissionId: $submissionId, note: $note)\n  }\n"], ["\n  mutation CreateNote($submissionId: ID!, $note: NoteInput!) {\n    createNote(submissionId: $submissionId, note: $note)\n  }\n"])));
export var CREATE_MIDLEVEL = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  mutation CreateMidlevel($submissionId: ID!, $midlevel: MidlevelInput!) {\n    createMidlevel(submissionId: $submissionId, midlevel: $midlevel)\n  }\n"], ["\n  mutation CreateMidlevel($submissionId: ID!, $midlevel: MidlevelInput!) {\n    createMidlevel(submissionId: $submissionId, midlevel: $midlevel)\n  }\n"])));
export var CREATE_ENTITY = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  mutation CreateEntity($submissionId: ID!, $entity: EntityInput!) {\n    createEntity(submissionId: $submissionId, entity: $entity)\n  }\n"], ["\n  mutation CreateEntity($submissionId: ID!, $entity: EntityInput!) {\n    createEntity(submissionId: $submissionId, entity: $entity)\n  }\n"])));
export var CREATE_PROVIDER = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  mutation CreateProvider($submissionId: ID!, $provider: ProviderInput!) {\n    createProvider(submissionId: $submissionId, provider: $provider)\n  }\n"], ["\n  mutation CreateProvider($submissionId: ID!, $provider: ProviderInput!) {\n    createProvider(submissionId: $submissionId, provider: $provider)\n  }\n"])));
export var UPDATE_SRF_ADJUSTMENT = gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  mutation UpdateSrfAdjustment(\n    $submissionId: ID!\n    $useCalculatedSrfAdjustment: Boolean\n    $srfAdjustment: Float\n    $calculatedSrfAdjustment: Float\n  ) {\n    updateSrfAdjustment(\n      submissionId: $submissionId\n      useCalculatedSrfAdjustment: $useCalculatedSrfAdjustment\n      srfAdjustment: $srfAdjustment\n      calculatedSrfAdjustment: $calculatedSrfAdjustment\n    ) {\n      srfAdjustment\n      useCalculatedSrfAdjustment\n    }\n  }\n"], ["\n  mutation UpdateSrfAdjustment(\n    $submissionId: ID!\n    $useCalculatedSrfAdjustment: Boolean\n    $srfAdjustment: Float\n    $calculatedSrfAdjustment: Float\n  ) {\n    updateSrfAdjustment(\n      submissionId: $submissionId\n      useCalculatedSrfAdjustment: $useCalculatedSrfAdjustment\n      srfAdjustment: $srfAdjustment\n      calculatedSrfAdjustment: $calculatedSrfAdjustment\n    ) {\n      srfAdjustment\n      useCalculatedSrfAdjustment\n    }\n  }\n"])));
export var RESET_QUICK_AUDIT = gql(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  mutation ResetQuickAudit($submissionId: ID!) {\n    resetQuickAudit(submissionId: $submissionId)\n  }\n"], ["\n  mutation ResetQuickAudit($submissionId: ID!) {\n    resetQuickAudit(submissionId: $submissionId)\n  }\n"])));
export var TOGGLE_IGNORE_LOCATION_INCONSISTENCY = gql(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  mutation ToggleIgnoreLocationInconsistency($submissionId: ID!, $providerId: ID!) {\n    toggleIgnoreLocationInconsistency(submissionId: $submissionId, providerId: $providerId)\n  }\n"], ["\n  mutation ToggleIgnoreLocationInconsistency($submissionId: ID!, $providerId: ID!) {\n    toggleIgnoreLocationInconsistency(submissionId: $submissionId, providerId: $providerId)\n  }\n"])));
export var UPDATE_SUBMISSION = gql(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  mutation UpdateSubmission($input: SubmissionUpdateInput!) {\n    updateSubmission(input: $input)\n  }\n"], ["\n  mutation UpdateSubmission($input: SubmissionUpdateInput!) {\n    updateSubmission(input: $input)\n  }\n"])));
export var DELETE_PROVIDER = gql(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  mutation DeleteProvider($input: DeleteProviderInput!) {\n    deleteProvider(input: $input)\n  }\n"], ["\n  mutation DeleteProvider($input: DeleteProviderInput!) {\n    deleteProvider(input: $input)\n  }\n"])));
export var DELETE_ENTITY = gql(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  mutation DeleteEntity($input: DeleteEntityInput!) {\n    deleteEntity(input: $input)\n  }\n"], ["\n  mutation DeleteEntity($input: DeleteEntityInput!) {\n    deleteEntity(input: $input)\n  }\n"])));
export var DELETE_MIDLEVEL = gql(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  mutation DeleteMidlevel($input: DeleteMidlevelInput!) {\n    deleteMidlevel(input: $input)\n  }\n"], ["\n  mutation DeleteMidlevel($input: DeleteMidlevelInput!) {\n    deleteMidlevel(input: $input)\n  }\n"])));
export var DELETE_NOTE = gql(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  mutation DeleteNote($input: DeleteNoteInput!) {\n    deleteNote(input: $input)\n  }\n"], ["\n  mutation DeleteNote($input: DeleteNoteInput!) {\n    deleteNote(input: $input)\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;
