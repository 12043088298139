import {
  Box,
  Text,
  Container,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  Image,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface NavbarProps {
  signOut: () => void;
  userEmail?: string;
}
export function Navbar({ userEmail, signOut }: NavbarProps) {
  const bgColor =
    window.location.host === "localhost:3000"
      ? "pink.100"
      : process.env.REACT_APP_ENV === "dev"
        ? "blue.200"
        : process.env.REACT_APP_ENV === "uat"
          ? "green.100"
          : "white";

  return (
    <Box position="fixed" w="100%" zIndex="13">
      <Flex position="sticky" borderBottom="1px" borderColor="gray.200" bgColor={bgColor}>
        <Container pr="64px" pl="30px">
          <Flex h="60px" alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" justifyContent="flex-start" gap="12px">
              <LinkBox>
                <LinkOverlay href="/">
                  <Image
                    minWidth="87px"
                    style={{ objectFit: "cover" }}
                    h="24px"
                    src="https://uploads-ssl.webflow.com/645029572ba4d37613789f79/645029572ba4d36e42789f80_230426_Indigo_Logo_Primary-p-1080.png"
                    alt="Indigo Logo"
                  />
                </LinkOverlay>
              </LinkBox>
              {process.env.REACT_APP_ENV === "uat" ? (
                <Text fontSize="xs">Testing environment</Text>
              ) : window.location.host === "localhost:3000" ? (
                <Text fontSize="xs">Local environment</Text>
              ) : process.env.REACT_APP_ENV === "dev" ? (
                <Text fontSize="xs">Development environment</Text>
              ) : (
                ""
              )}
            </Flex>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon />}>
                    {userEmail || "publicuniversalfriend@getindigo.com"}
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={signOut}>Sign out</MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
        </Container>
      </Flex>
    </Box>
  );
}
