import { z } from "zod";
export var GetPolicyOutputSchema = z.object({
    ListOfErrors: z.array(z.string()).optional(),
    PolicyJSON: z.object({
        ListofProductVersion: z.array(z.object({
            ProductVersionName: z.string(),
        })),
        Process: z.string(),
        ExternalQuoteNumber: z.string(),
        QuoteNumber: z.number(),
        FullNumber: z.string(),
        LegacyPolicyNumber: z.string(),
        PolicyHolderNumber: z.string(),
        PolicyTerm: z.string(),
        EffectiveDate: z.string(),
        BillingFrequency: z.string(),
        MPLPolicyTransaction: z.string(),
        PolicyType: z.string(),
        MPLPolicyForm: z.string(),
        IsPolicyOnRecurringPaymentSchedule: z.boolean(),
        IsPolicyOnRecurringPaymentScheduleLastUpdateDate: z.string(),
        ListofCoverages: z.array(z.object({
            MyOfferedCoverage: z.object({
                CoverageName: z.string(),
            }),
            ExcludeFromCommissions: z.boolean(),
            Premium: z.object({
                Currency: z.string(),
                Amount: z.number(),
            }),
            OccurrenceLimit: z.object({
                Currency: z.string(),
                Amount: z.number(),
            }),
            Deductible: z.object({
                Currency: z.string(),
                Amount: z.number(),
            }),
            PerPersonLimit: z.object({
                Currency: z.string(),
                Amount: z.number(),
            }),
            PerAccidentLimit: z.object({
                Currency: z.string(),
                Amount: z.number(),
            }),
            CoverageClaimType: z.string(),
            PriorActsDate: z.string(),
            ListofLimitsDeductibles: z.array(z.object({
                AmountValue: z.object({
                    Currency: z.string(),
                    Amount: z.number(),
                }),
                LimitDeductibleLabel: z.string(),
                DeductibleNames: z.string(),
                LimitDeductibleType: z.string(),
                LimitDeductibleCategory: z.string(),
                "PolicyCoverage-Filed": z.string(),
            })),
            ServiceExternalIdentifier: z.string(),
            MPLIndividualProviderSharedOrSeparateLimits: z.string(),
            MPLEntitySharedOrSeparateLimits: z.string(),
            MPLIndividualProviderDeductibleStructureFactor: z.string(),
            CoverageInsuredItemIdentifierKey: z.string(),
            CoverageInsuredItemExternalIdentifier: z.string().optional(),
        })),
        RequestedDocuments: z.array(z.unknown()),
        AllInsuredItemlist: z.array(z.object({
            AllInsuredItemNumber: z.number(),
            InsuredItemNumberForTheCategory: z.number(),
            ParentNode: z.object({
                InsuredItemNumberParentNode: z.number(),
            }),
            AllItemsIdentifierKey: z.string(),
        })),
        ListofGroupInsuredItems: z.array(z.object({
            MyInsuredCustomer: z.object({
                RiskCategory: z.string(),
                RiskCustomerNumber: z.string(),
                RiskName: z.string(),
                RestrictedCustomer: z.boolean(),
                FirstName: z.string().nullable(),
                LastName: z.string().nullable(),
                CustomerEmail: z.string(),
                CustomerCellPhone: z.string(),
                WorkPhone: z.string().nullable(),
                Suffix: z.string().nullable(),
            }),
            GroupInsuredItemIdentifierKey: z.string(),
        })),
        ListofInsuredItems: z.array(z.object({
            GroupInsuredItemExternalIdentifier: z.string(),
            ListofRisks: z.array(z.object({
                InsuredItemIdentifierKey: z.string(),
                ListofSubRisks: z.array(z.object({
                    InsuredItemDescription: z.string(),
                }).optional()),
            })),
        })),
        ListofExternalServicingActors: z.array(z.object({
            EntityType: z.string(),
            CarrierName: z.string().nullable(),
            CarrierCode: z.string().nullable(),
            Commission: z.string(),
            ActorName: z.string(),
            AgentTitle: z.string().nullable(),
            MyAgentFunctionalId: z.string().nullable(),
            MyAgencyFunctionalId: z.string().nullable(),
        })),
        TotalDue: z.object({
            Currency: z.string(),
            Amount: z.number(),
        }),
        MPLPolicyRenewalType: z.string(),
        IdentifierKey: z.string(),
        ListOfRisksForWebAPI: z.array(z.unknown()),
        "MPL-ExcessMinimumPremium": z.string(),
        ListofPremiumFactors: z.array(z.object({
            TargetFactor: z.string(),
            PriceFactorDiscountOrSurcharge: z.string(),
            ListofApplicableInsuredItems: z.array(z.object({
                CustomerNumber: z.string().optional(),
            })),
            subPriceFactorNameToDisplay: z.string(),
        })),
        AgentBillType: z.string(),
        MPLPolicyMinimumPremium: z.object({
            Currency: z.string(),
            Amount: z.number(),
        }),
        ModelID: z.string(),
        PreviousCarrier: z.string(),
        InquiryIDQuote: z.string(),
        ListofInternalServicingActors: z.array(z.object({
            InternalEntityType: z.string(),
        })),
        QuoteExpiringPremium: z.object({
            Currency: z.string(),
            Amount: z.number(),
        }),
        QuoteRenewalPremium: z.object({
            Currency: z.string(),
            Amount: z.number(),
        }),
        QuoteTargetPremium: z.object({
            Currency: z.string(),
            Amount: z.number(),
        }),
        InitialSubmissionGrade: z.string(),
        InitialSubmissionFull: z.boolean(),
        "Status–UserAssigned": z.string(),
        DeclineQuoteReason: z.string(),
        OtherDeclineReason: z.string(),
        ObjectVersion: z.string(),
    }).optional()
});
var GetPolicyRiskOutputSchema = z.object({
    PolicyJSON: z.object({
        IdentifierKey: z.string(),
        PolicyTerm: z.string(),
        MPLPolicyTransaction: z.string(),
        PolicyType: z.string(),
        EffectiveDate: z.string(),
        ListofRisks: z.array(z.object({
            InsuredItemIdentifierKey: z.string(),
            CustomerNumber: z.string(),
            InsuredItemDescription: z.string(),
            RiskEffectiveDate: z.string(),
            "MPL-IP-IndividualProviderType": z.enum(["1901", "1902"]).optional().nullable(),
            "MPL-IP-IndividualProviderSpecialty": z.string().optional(),
            BrokerSpecialty: z.string().nullable().optional(),
            IndividualProviderModelRiskScore: z.string().optional().nullable(),
        })),
        ObjectVersion: z.string(),
    }),
});
