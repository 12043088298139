import { useState, useEffect, ChangeEvent } from "react";
import {
  PROVIDER_SUFFIXES,
  DEFAULT_MRS_VALUE,
  DEFAULT_INCONSISTENT_LOCATION_MRS_VALUE,
} from "app-constants";
import { useMutation, useQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import {
  RenewalSummary,
  ProviderRenewalTable,
  MidlevelRenewalTable,
  ProviderRenewal,
  ProviderRenewalFlags,
  InsightDetails,
} from "components";
import { formatAsDollar, formatStateLicenses } from "utils";
import {
  ProviderUpdateInput,
  SubmissionUpdateInput,
  BoardAction,
  Factor,
  Claim,
} from "__generated__/graphql";
import {
  CALCULATE_INSIGHTS_DATA,
  RESET_QUICK_AUDIT,
  TOGGLE_IGNORE_LOCATION_INCONSISTENCY,
  UPDATE_PROVIDERS,
  UPDATE_SUBMISSION,
} from "mutations";
import { GET_PREVIOUS_SUBMISSION, GET_SUBMISSION } from "queries";
import { InsightDetailType } from "localTypes";

interface RenewalWrapperProps {
  data: SubmissionUpdateInput;
  srf?: number;
  mrs?: number;
  previousSubmissionId: string;
  flags: ProviderRenewalFlags[];
  fetchAndSaveMrs: () => Promise<void>;
  calculatedSrfAdjustment: number;
}

export function RenewalWrapper({
  flags,
  previousSubmissionId,
  mrs,
  srf,
  calculatedSrfAdjustment,
  data,
  fetchAndSaveMrs,
}: RenewalWrapperProps) {
  const [updateSubmission] = useMutation(UPDATE_SUBMISSION, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [resetQuickAudit] = useMutation(RESET_QUICK_AUDIT, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [updateProviders] = useMutation(UPDATE_PROVIDERS, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [toggleIgnoreLocationInconsistency] = useMutation(TOGGLE_IGNORE_LOCATION_INCONSISTENCY, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState<string | undefined>();
  const selectedProvider = data?.providers?.find((provider) => provider.id === selectedProviderId);
  const [selectedInsightType, setSelectedInsightType] = useState<InsightDetailType>(
    InsightDetailType.Claims,
  );
  const { data: previousSubmissionData } = useQuery(GET_PREVIOUS_SUBMISSION, {
    variables: { id: previousSubmissionId },
  });

  const [calculateInsightsData, { data: insightsDataRaw, loading: insightsDataIsLoading }] =
    useMutation(CALCULATE_INSIGHTS_DATA);
  const oldInsightsData = insightsDataRaw?.calculateInsightsData;

  useEffect(() => {
    if (data?.isQuickAuditEnabled && (data?.providers?.filter((p) => !p?.mrs)?.length || 0) > 0) {
      fetchAndSaveMrs();
    }
  }, [data?.isQuickAuditEnabled, data?.providers, fetchAndSaveMrs]);

  useEffect(() => {
    if (oldInsightsData === undefined && insightsDataIsLoading === false) {
      calculateInsightsData({
        variables: {
          submissionId: previousSubmissionId,
        },
      });
    }
  }, [previousSubmissionId, calculateInsightsData, oldInsightsData, insightsDataIsLoading]);
  const validPremium =
    data?.isQuickAuditEnabled ?? true ? data?.previewPremium : data?.manualPreviewPremium;
  return (
    <Box
      m="0px 30px 30px 30px"
      w="100%"
      maxW="1040px"
      minW={{ lg: "930px", md: "700px", sm: "400px" }}
      pl="10px"
      pr="15px"
    >
      <RenewalSummary
        calculatedSrfAdjustment={calculatedSrfAdjustment}
        useCalculatedSrfAdjustment={
          !!(data?.useCalculatedSrfAdjustment || data?.isQuickAuditEnabled)
        }
        handleSaveAdjustment={async (adjustment: number) => {
          try {
            const isResetting = adjustment === calculatedSrfAdjustment;
            const noManualProviderSpecialties = data?.providers?.every(
              (p) =>
                p.manualIndigoSpecialty === p.indigoSpecialty || p.manualIndigoSpecialty === null,
            );
            await updateSubmission({
              variables: {
                input: {
                  id: data?.id || "",
                  srfAdjustment: adjustment,
                  useCalculatedSrfAdjustment: isResetting,
                  isQuickAuditEnabled: isResetting && noManualProviderSpecialties,
                },
              },
            });
            if (data?.isQuickAuditEnabled ?? true) {
              await updateProviders({
                variables: {
                  submissionId: data?.id || "",
                  providers: data?.providers?.map((p) => ({
                    id: p.id,
                    manualIndigoSpecialty: p.indigoSpecialty,
                    manualMrs: p.mrs,
                  })),
                },
              });
            }

            fetchAndSaveMrs();
          } catch (e) {
            console.error(e);
          }
        }}
        isLoading={isLoading}
        isDisabled={false}
        newPricing={formatAsDollar.format(validPremium || 0)}
        oldPricing={formatAsDollar.format(data?.quoteProperties?.initialPremium || 0)}
        oldMrs={oldInsightsData?.averageMrs || 0}
        newMrs={mrs || 0}
        srf={srf || 0}
        adjustment={
          data?.useCalculatedSrfAdjustment || data?.isQuickAuditEnabled
            ? calculatedSrfAdjustment
            : data?.srfAdjustment
        }
        entityName={data?.entities?.[0]?.name || "Provider Group"}
        isEntity={(data?.entities?.length || 0) > 0}
      />
      <ProviderRenewalTable
        handleSetSelectedProviderId={(providerId: string) => setSelectedProviderId(providerId)}
        handleSetSelectedInsightType={(type: InsightDetailType) => setSelectedInsightType(type)}
        isLoading={isLoading}
        isLocked={false}
        handleChangeSpecialty={async (
          specialty: ChangeEvent<HTMLSelectElement>,
          providerId: string,
        ) => {
          try {
            setIsLoading(true);
            const updatedProviders = data?.providers?.map((p) => {
              if (p.id === providerId) {
                return {
                  id: p.id,
                  manualIndigoSpecialty: specialty.target.value,
                };
              }
              if (data?.isQuickAuditEnabled) {
                return {
                  id: p.id,
                  manualIndigoSpecialty: p.indigoSpecialty,
                  manualMrs: p.mrs,
                };
              }
              return {
                id: p.id,
              };
            });
            await updateProviders({
              variables: {
                submissionId: data?.id || "",
                providers: updatedProviders,
              },
            });
            await updateSubmission({
              variables: {
                input: {
                  id: data?.id || "",
                  isQuickAuditEnabled: false,
                  manualPreviewPremiumsAreStale: true,
                  useCalculatedSrfAdjustment: true,
                },
              },
            });
            await fetchAndSaveMrs();
          } catch (e) {
            console.error(e);
          } finally {
            setIsLoading(false);
          }
        }}
        flags={flags}
        providers={data?.providers?.map((provider: ProviderUpdateInput): ProviderRenewal => {
          const oldProvider = previousSubmissionData?.getSubmission?.providers.find(
            (p: ProviderUpdateInput) => p.npi === provider.npi,
          );
          const validMrs =
            data?.isQuickAuditEnabled ?? true
              ? provider.mrs
              : provider.ignoreLocationInconsistency ?? true
                ? provider.manualMrs || provider.mrs
                : DEFAULT_INCONSISTENT_LOCATION_MRS_VALUE;
          const validSpecialty =
            data?.isQuickAuditEnabled ?? true
              ? provider.indigoSpecialty
              : provider.manualIndigoSpecialty || provider.indigoSpecialty;
          const validPreviewPremium =
            data?.isQuickAuditEnabled ?? true
              ? provider.previewPremium
              : provider.manualPreviewPremium;
          return {
            id: provider.id,
            firstName: provider.firstName,
            lastName: `${provider.lastName}${provider.suffix ? `,` : ""}`,
            suffix: provider.suffix ? PROVIDER_SUFFIXES[provider.suffix] : "",
            indigoSpecialty: validSpecialty,
            scheduledRatingFactor: srf,
            mrs: validMrs,
            oldMrs: oldProvider?.mrs,
            pricing: formatAsDollar.format(validPreviewPremium || 0),
            oldPricing: formatAsDollar.format(provider?.initialPremium || 0),
            hasEndorsement: provider.hasEndorsement,
          };
        })}
      />
      {(data?.midlevels?.length || 0) > 0 && (
        <MidlevelRenewalTable
          midlevels={data?.midlevels?.map((midlevel) => {
            const validPreviewPremium =
              data?.isQuickAuditEnabled ?? true
                ? midlevel.previewPremium
                : midlevel.manualPreviewPremium;
            return {
              id: midlevel.id,
              firstName: midlevel.firstName,
              lastName: midlevel.lastName,
              suffix: midlevel.suffix,
              oldMrs: oldInsightsData?.averageMrs,
              mrs: mrs || 0,
              pricing: formatAsDollar.format(validPreviewPremium || 0),
              oldPricing: formatAsDollar.format(midlevel?.initialPremium || 0),
              specialty: midlevel?.indigoSpecialty || "",
            };
          })}
        />
      )}
      {selectedProviderId !== undefined && selectedProvider && (
        <InsightDetails
          id={selectedProvider.id || ""}
          redFlags={selectedProvider.redFlags || []}
          hasLargeGroup={(data?.providers?.length && data?.providers?.length > 1) || false}
          stateLicenses={
            selectedProvider.stateLicenses
              ? formatStateLicenses(selectedProvider.stateLicenses)
              : []
          }
          isIgnoringLocationInconsistency={
            data?.isQuickAuditEnabled === false
              ? selectedProvider?.ignoreLocationInconsistency
              : true
          }
          setIgnoreLocationInconsistency={async () => {
            try {
              if (data?.isQuickAuditEnabled) {
                await resetQuickAudit({
                  variables: {
                    submissionId: data?.id || "",
                  },
                });
              }
              await toggleIgnoreLocationInconsistency({
                variables: {
                  submissionId: data?.id,
                  providerId: selectedProvider.id,
                },
              });
            } catch (e) {
              console.error(e);
            }
          }}
          boardActions={(selectedProvider.boardActions as BoardAction[]) || []}
          factors={(selectedProvider?.negativeFactors || []) as Factor[]}
          mrs={
            (data?.isQuickAuditEnabled ? selectedProvider.mrs : selectedProvider.manualMrs) ||
            DEFAULT_MRS_VALUE
          }
          mrsTrend={selectedProvider.mrsTrends}
          payerData={selectedProvider?.billingData?.payerData || []}
          hvData={selectedProvider?.billingData?.hvData || []}
          locationInconsistencies={selectedProvider?.inconsistencies?.location}
          specialtyInconsistencies={selectedProvider?.inconsistencies?.specialty}
          type={selectedInsightType}
          claims={(selectedProvider?.claims as Claim[]) || []}
          name={`${selectedProvider.firstName} ${selectedProvider.lastName}`}
          onClose={() => setSelectedProviderId(undefined)}
        />
      )}
    </Box>
  );
}
