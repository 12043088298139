var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Sha256 } from "@aws-crypto/sha256-js";
import { HttpRequest } from "@smithy/protocol-http";
import { SignatureV4 } from "@smithy/signature-v4";
import { ApiError } from "@app-stack/api_client/src/errors";
var BaseApiClient = /** @class */ (function () {
    function BaseApiClient(url, region, httpClient, credentialProvider) {
        this.httpClient = httpClient;
        this.apiBaseUrl = url;
        this.requestSigner = new SignatureV4({
            credentials: credentialProvider,
            region: region,
            service: "execute-api",
            sha256: Sha256,
        });
    }
    BaseApiClient.prototype.constructHttpRequest = function (method, path, body) {
        var apiUrl = new URL(path, this.apiBaseUrl);
        var reqOptions = {
            method: method,
            headers: {
                accept: "*/*",
                host: apiUrl.hostname,
            },
            hostname: apiUrl.hostname,
            path: apiUrl.pathname,
            body: body ? JSON.stringify(body) : undefined,
        };
        if (body) {
            reqOptions.headers["Content-Type"] = "application/json";
        }
        return new HttpRequest(reqOptions);
    };
    BaseApiClient.prototype.logError = function (message, error) {
        console.error("".concat(message, ": ").concat(error.message));
        console.error(error.stack);
    };
    BaseApiClient.prototype._sendRequest = function (obj) {
        return __awaiter(this, void 0, Promise, function () {
            var signedRequest, response, message, json;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestSigner.sign(obj)];
                    case 1:
                        signedRequest = _a.sent();
                        return [4 /*yield*/, this.httpClient.request({
                                method: signedRequest.method,
                                url: "".concat(this.apiBaseUrl).concat(signedRequest.path),
                                headers: signedRequest.headers,
                                data: signedRequest.body,
                            })];
                    case 2:
                        response = _a.sent();
                        console.log("API request returned with: " + response.status + "-" + response.statusText);
                        if (response.status < 400) {
                            if (typeof response.data === "string") {
                                return [2 /*return*/, JSON.parse(response.data)];
                            }
                            else {
                                return [2 /*return*/, response.data];
                            }
                        }
                        else {
                            message = response.statusText;
                            if (response.data && typeof response.data === "string") {
                                try {
                                    json = JSON.parse(response.data);
                                    if (json.message) {
                                        message = json.message;
                                    }
                                }
                                catch (e) {
                                    message = response.data;
                                }
                            }
                            throw new ApiError(response.status, message);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return BaseApiClient;
}());
export { BaseApiClient };
