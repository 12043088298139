import { useContext, useCallback, useState, useEffect } from "react";
import { ApiClientContext } from "providers";
import { useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ExternalLinkIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  Divider,
  Flex,
  FormLabel,
  Select,
  Text,
  Textarea,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { UPDATE_SUBMISSION } from "mutations";
import { GET_SUBMISSION } from "queries";
import { CardFooter } from "components";
import { useRiskColor } from "hooks";
import { SubmissionForm } from "./SubmitInquiry/SubmissionForm";
import { RiskSummary } from "./SubmitInquiry/RiskSummary";
import { DocumentUploader } from "./SubmitInquiry/DocumentUploader";
import { RiskLevel } from "@app-stack/types/risk_color";
import { useAnalytics } from "providers";
import { RAT_EVENTS } from "analytics";
import {
  Limit,
  LimitType,
  Deductible,
  SubmissionUpdateInput,
  Submission,
  ProviderUpdateInput,
  PolicyType,
  StatusType,
} from "__generated__/graphql";

interface SubmitProps {
  submission: SubmissionUpdateInput;
}
export function Submit({ submission }: SubmitProps) {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const { id } = useParams();
  const [mutateFunction] = useMutation(UPDATE_SUBMISSION, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isHighRiskBrokerInquiry, setIsHighRiskBrokerInquiry] = useState<boolean>();
  const [overrideHighRiskFormBlocker, setOverrideHighRiskFormBlocker] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<string | undefined>();
  const [riskLevel, setRiskLevel] = useState<RiskLevel | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [hasBeenReset, setHasBeenReset] = useState(false);
  const apiClient = useContext(ApiClientContext);
  const { riskColor, fetchRiskColor } = useRiskColor();
  const defaultProviders = submission?.providers?.map((provider) => ({
    ...provider,
    limit: provider.limit ?? Limit["1M_3M"],
    limitType: provider.limitType ?? LimitType.Separate,
    deductible: provider.deductible ?? Deductible.None,
  }));
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting, errors },
    reset,
    setValue,
    clearErrors,
  } = useForm<SubmissionUpdateInput & { documents?: File[] }>({
    defaultValues: {
      id: id,
      providers: defaultProviders,
      notes: submission?.notes || "",
      policyType: submission?.policyType || PolicyType.ClaimsMade,
    },
  });
  const formData = watch();

  useEffect(() => {
    if (submission && hasBeenReset !== true) {
      setHasBeenReset(true);
      reset(submission);
    }
  }, [submission, hasBeenReset, reset]);

  const fetchData = useCallback(async () => {
    if (!submission) return;
    if (submission) {
      fetchRiskColor(submission as Submission);
    }
    const isHighRisk = riskColor === RiskLevel.HIGH; // TODO figure out what this needs to be
    setIsHighRiskBrokerInquiry(isHighRisk);
    setRiskLevel(riskColor);
    setIsLoading(false);
  }, [fetchRiskColor, riskColor, submission]);

  useEffect(() => {
    fetchData();
  }, [submission, fetchData]);

  async function onSubmit() {
    try {
      const filenames: string[] = selectedFiles.map((file) => file.name);

      const urls = await apiClient?.getSubmissionDocumentPostUrls({
        submissionId: id || "",
        filenames,
      });
      if (!urls) {
        throw new Error("Failed to get upload URLs");
      }

      await Promise.all(
        selectedFiles.map(async (file) => {
          const url = urls.filesToUrls?.[file.name];
          if (!url) {
            throw new Error("Failed to get upload URL");
          }
          await fetch(url, {
            method: "PUT",
            body: file,
          });
        }),
      );

      await mutateFunction({
        variables: {
          input: {
            ...formData,
            id: id,
            status: StatusType.RatOfficial,
          },
        },
      });
      navigate(`/broker/inquiry/${id}/confirm`);
    } catch (e) {
      console.error(e);
      console.warn("Error submitting", e);
      setSubmissionError("Something went wrong submitting your inquiry.");
    }
  }

  return (
    <Container data-testid="rat-submit-page" pt="100px" maxW="833px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card mb="42px" maxWidth="833px" border="1px" borderColor="gray.150">
          <CardHeader p="0" bg="indigo.50" fontSize="sm">
            <Flex px="25px" py="15px" alignItems="center" justifyContent="space-between">
              Please review risk profiles and follow directions below
            </Flex>
          </CardHeader>
          <CardBody pt="0px">
            {submissionError && <Text color="red">{submissionError}</Text>}
            <RiskSummary isLoading={isLoading} riskBucket={riskLevel} />
            {submission?.providers?.map((provider: ProviderUpdateInput, index: number) => {
              const address = watch(`providers.${index}.address`);
              return (
                (!isHighRiskBrokerInquiry || overrideHighRiskFormBlocker) && (
                  <div key={provider.id}>
                    <SubmissionForm
                      clearErrors={clearErrors}
                      setValue={setValue}
                      index={index}
                      register={register}
                      control={control}
                      address={address}
                    />
                    <Divider my="40px" />
                  </div>
                )
              );
            })}
            {isHighRiskBrokerInquiry && !overrideHighRiskFormBlocker && (
              <Flex fontSize="xm" fontWeight="600" direction={["column", "row"]}>
                <Button
                  colorScheme="indigo"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Cancel Application
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="red"
                  onClick={() => {
                    setOverrideHighRiskFormBlocker(true);
                  }}
                >
                  Continue With Application Anyway
                </Button>
              </Flex>
            )}
          </CardBody>
          {(!isHighRiskBrokerInquiry || overrideHighRiskFormBlocker) && (
            <CardFooter>
              <Box mb={6}>
                <FormLabel htmlFor="policy-type">Policy Type</FormLabel>
                <Select id="policy-type" {...register("policyType")}>
                  <option></option>
                  <option value={PolicyType.ClaimsMade}>Claims Made</option>
                  <option value={PolicyType.Occurrence}>Occurrence</option>
                </Select>
              </Box>
              <Flex mb={6} direction="column">
                <Text fontWeight="500">
                  Please upload the declaration page for this submission. If there are any
                  additional documents you would like to share with us, you can upload them below,
                  too.
                </Text>
                <Flex mb="15px">
                  <Text fontWeight="500">Or email them to:</Text>
                  <Box color="indigo" ml="3px" fontWeight="700">
                    <a href="mailto:submissions@getindigo.com"> submissions@getindigo.com</a>
                  </Box>
                </Flex>
                <DocumentUploader
                  error={errors?.documents && errors?.documents?.message}
                  setSelectedFiles={setSelectedFiles}
                  selectedFiles={selectedFiles}
                />
                <Box mt={6}>
                  <FormLabel htmlFor="notes">Additional Notes</FormLabel>
                  <Textarea bg="white" id="notes" {...register("notes")} />
                </Box>
              </Flex>
              <Text fontSize="sm" color="gray.500" mb={6}>
                Please note that this tool is designed to provide a preliminary indication of risk
                based on currently available information, and is subject to change due to receipt of
                new information. Therefore, there may be circumstances that could lead Indigo to
                decline to write a risk, even if the initial risk indication is “green.” For more
                complete acceptance criteria, please refer to{" "}
                <ChakraLink
                  color="blue.500"
                  href="https://uploads-ssl.webflow.com/64934c3053c8cc558f40d930/65fb064a8b1bc4e21a047849_indigo-warranty-statement.pdf"
                  download="indigo-warranty-statement.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Indigo’s warranty statement
                  <ExternalLinkIcon fontSize="0.75rem" ml="4px" />
                </ChakraLink>
                .
              </Text>
            </CardFooter>
          )}
        </Card>
        <Flex justifyContent="flex-end" mt="30px">
          <Button
            isDisabled={isHighRiskBrokerInquiry && !overrideHighRiskFormBlocker}
            isLoading={isSubmitting}
            type="submit"
            rightIcon={<ArrowForwardIcon />}
            colorScheme="indigo"
            onClick={() => analytics?.track(RAT_EVENTS.SUBMIT_INQUIRY)}
          >
            Submit Applications
          </Button>
        </Flex>
      </form>
    </Container>
  );
}
