import {
  Badge,
  Box,
  Button,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Text,
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  Maybe,
  Scalars,
  InputMaybe,
  SpecialtyInconsistencyInput,
  LocationInconsistencyInput,
} from "__generated__/graphql";

interface ConsistencyDetailsProps {
  location?: InputMaybe<Array<InputMaybe<LocationInconsistencyInput>>>;
  specialty?: InputMaybe<Array<InputMaybe<SpecialtyInconsistencyInput>>>;
  setIgnoreLocationInconsistency?: () => Promise<void>;
  isIgnoringLocationInconsistency?: Maybe<Scalars["Boolean"]["output"]>;
}

export function ConsistencyDetails({
  isIgnoringLocationInconsistency,
  setIgnoreLocationInconsistency,
  specialty,
  location,
}: ConsistencyDetailsProps) {
  const locationConsistency = location?.[0];
  const specialtyConsistency = specialty?.[0];

  return (
    <Flex w="100%" gap="16px" direction="column">
      <Card>
        <CardHeader fontWeight="bold" height="115%" p="16px 16px 8px">
          Consistency Summary
        </CardHeader>
        <CardBody>
          <Flex gap="24px" alignItems="center" p="0px">
            {specialty?.some((s) => !s?.isConsistent) ? (
              <Flex gap="8px" alignItems="center">
                <WarningTwoIcon fontSize="xm" color="red.500" />
                <Text fontSize="sm">Inconsistent Specialty</Text>
              </Flex>
            ) : (
              <Flex gap="8px" alignItems="center">
                <CheckCircleIcon fontSize="xm" color="green.500" />
                <Text fontSize="sm">Consistent Specialty</Text>
              </Flex>
            )}
            {location?.some((s) => !s?.isConsistent) ? (
              <Flex gap="8px" alignItems="center">
                <WarningTwoIcon fontSize="xm" color="red.500" />
                <Text fontSize="sm">Inconsistent Location</Text>
              </Flex>
            ) : (
              <Flex gap="8px" alignItems="center">
                <CheckCircleIcon fontSize="xm" color="green.500" />
                <Text fontSize="sm">Consistent Location</Text>
              </Flex>
            )}
          </Flex>
        </CardBody>
      </Card>
      {!!specialty?.length && (
        <Card>
          {specialty.some((s) => !s?.isConsistent) ? (
            <CardHeader fontWeight="bold" lineHeight="115%">
              Specialty Inconsistencies
            </CardHeader>
          ) : (
            <CardHeader>Specialty Consistencies</CardHeader>
          )}
          <CardBody>
            <Card border="1px solid" borderColor="gray.300" boxShadow="none">
              <Box>
                <SimpleGrid
                  columns={4}
                  gap="24px"
                  fontSize="xs"
                  fontWeight="bold"
                  color="gray.600"
                  padding="16px 12px"
                  style={{ textTransform: "uppercase" }}
                >
                  {specialtyConsistency?.declared && <Text fontWeight="900">Declared</Text>}
                  {specialtyConsistency?.ds && <Text fontWeight="900">DS Specialty</Text>}
                  {specialtyConsistency?.inferred && <Text>Inferred</Text>}
                  {specialtyConsistency?.nppes && <Text>NPPES</Text>}
                </SimpleGrid>
              </Box>
              <SimpleGrid
                backgroundColor="gray.100"
                padding="16px 12px"
                fontSize="xs"
                columns={4}
                gap="24px"
              >
                {specialtyConsistency?.declared && (
                  <Text fontWeight="800">{specialtyConsistency.declared}</Text>
                )}
                {specialtyConsistency?.ds && (
                  <Text fontWeight="800">{specialtyConsistency.ds}</Text>
                )}
                {specialtyConsistency?.inferred && <Text>{specialtyConsistency.inferred}</Text>}
                {specialtyConsistency?.nppes && <Text>{specialtyConsistency.nppes}</Text>}
              </SimpleGrid>
            </Card>
          </CardBody>
        </Card>
      )}
      {!!location?.length && (
        <Card>
          {location.some((s) => !s?.isConsistent) ? (
            <CardHeader>
              <Flex justifyContent="space-between" lineHeight="115%" fontWeight="bold">
                Location Inconsistencies{" "}
                {isIgnoringLocationInconsistency === false ? (
                  ""
                ) : (
                  <Badge variant="boxy">IGNORED</Badge>
                )}
              </Flex>
            </CardHeader>
          ) : (
            <CardHeader>Location Consistencies</CardHeader>
          )}
          <CardBody>
            <TableContainer w="100%" border="1px solid" borderRadius="3px" borderColor="gray.300">
              <Table fontSize="xs" variant="striped">
                <Thead>
                  <Tr>
                    {locationConsistency?.ds && <Th>DS</Th>}
                    {locationConsistency?.iqvia && <Th>IQVIA</Th>}
                    {locationConsistency?.declared && <Th>Declared</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    {locationConsistency?.ds && <Td>{locationConsistency.ds || ""}</Td>}
                    {locationConsistency?.iqvia && <Td>{locationConsistency.iqvia || ""}</Td>}
                    {locationConsistency?.declared && <Td>{locationConsistency.declared || ""}</Td>}
                  </Tr>
                </Tbody>
              </Table>
              {location.some((s) => !s?.isConsistent) ? (
                <Button onClick={setIgnoreLocationInconsistency} size="sm" colorScheme="red">
                  {isIgnoringLocationInconsistency !== false ? "Respect" : "Ignore"} Location
                  Inconsistency
                </Button>
              ) : (
                ""
              )}
            </TableContainer>
          </CardBody>
        </Card>
      )}
    </Flex>
  );
}
