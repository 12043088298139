import { useEffect, ChangeEvent, useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  Card,
  CardBody,
  Flex,
  Text,
  Spacer,
  Tooltip,
} from "@chakra-ui/react";
import { RefreshIcon } from "assets";
import { GroupCardSummary } from "./GroupCardSummary";
import { SuggestedActionTag, PercentInput, Datum, Rolodex } from "components";
import { SuggestedAction } from "localTypes";
import { ExpiredIcon } from "assets";
import { useAppetite, useStalePricing } from "providers";
import "./group-card.css";

interface GroupCardProps {
  isLoadingToggle: boolean;
  name: string;
  providersCount: number;
  county: string;
  averageMRS?: number;
  bookedSRF?: number;
  srfAdjustment?: number;
  calculatedSrfAdjustment?: number;
  useCalculatedSrfAdjustment?: boolean;
  summaryText?: string;
  npi?: string;
  onSaveAdjustment: (adjustmentValue: number) => Promise<unknown>;
  onSaveUseCalculatedSrfAdjustment: (useCalculated: boolean) => Promise<unknown>;
  customerId?: string;
  indigoPremium?: string;
  targetPremium?: string;
  averageAdjustedMrs: number;
  isLocked?: boolean;
}

export function GroupCard({
  isLocked,
  isLoadingToggle,
  name,
  providersCount,
  county,
  srfAdjustment,
  calculatedSrfAdjustment,
  useCalculatedSrfAdjustment,
  bookedSRF,
  averageMRS,
  summaryText,
  onSaveAdjustment,
  onSaveUseCalculatedSrfAdjustment,
  customerId,
  indigoPremium,
  targetPremium,
  averageAdjustedMrs,
}: GroupCardProps) {
  const appetite = useAppetite();
  let suggestedAction = SuggestedAction.Quote;
  switch (appetite?.action) {
    case SuggestedAction.Quote:
      suggestedAction = SuggestedAction.Quote;
      break;
    case SuggestedAction.Decline:
      suggestedAction = SuggestedAction.Decline;
      break;
    case SuggestedAction.Review:
      suggestedAction = SuggestedAction.Review;
      break;
    default:
      break;
  }
  const adjustment = (useCalculatedSrfAdjustment ? calculatedSrfAdjustment : srfAdjustment) || 0;
  const hasStalePricing = useStalePricing();
  const mrsReadable = averageMRS ? averageMRS : 0;
  const bookedSRFReadable = bookedSRF || bookedSRF === 0 ? bookedSRF : 0;
  const [isLoading, setIsLoading] = useState(false);
  const [adjustmentValue, setAdjustmentValue] = useState<string | undefined>(`${adjustment || 0}`);

  useEffect(() => {
    setAdjustmentValue(`${adjustment}`);
  }, [adjustment]);

  useEffect(() => {
    if (useCalculatedSrfAdjustment) {
      setAdjustmentValue(calculatedSrfAdjustment?.toString() || "0");
    }
  }, [useCalculatedSrfAdjustment, calculatedSrfAdjustment]);

  function customerIdent() {
    if (!customerId) {
      return (
        <Flex>
          <Datum label="" value="" />
        </Flex>
      );
    } else {
      return (
        <Flex>
          <Datum label="Simplify Customer ID" value={customerId} />
        </Flex>
      );
    }
  }
  async function handleSetUseCalculatedSrfAdjustment() {
    setIsLoading(true);
    setAdjustmentValue(calculatedSrfAdjustment?.toString() || "0");
    await onSaveUseCalculatedSrfAdjustment(true);
    setIsLoading(false);
  }

  async function handleSaveAdjustment() {
    if (adjustmentValue === undefined) return;
    if (parseInt(adjustmentValue) === adjustment) return;
    setIsLoading(true);
    let numValue = parseFloat(adjustmentValue);
    if (Number.isNaN(numValue)) numValue = 0;
    await onSaveAdjustment(numValue);
    setIsLoading(false);
  }

  useEffect(() => {
    if (adjustmentValue === undefined && adjustment) {
      setAdjustmentValue(`${adjustment}`);
    }
  }, [adjustment, adjustmentValue]);
  return (
    <Card mb="12px" maxWidth="1128px" w="100%">
      <CardBody p="0px">
        <Flex
          direction={{ lg: "row", md: "row", base: "column" }}
          borderBottom="1px"
          borderBottomColor="gray.300"
          p="20px"
          alignContent="center"
        >
          <Box>
            <Text color="gray.450" fontWeight="normal" lineHeight="shorter">
              Group Summary
            </Text>
            <Text lineHeight="normal" fontSize="3xl">
              {name}
            </Text>
          </Box>
          <Spacer />

          <Flex
            alignItems={{ lg: "center", md: "center", base: "start" }}
            gap={{ lg: "24px", md: "12px", base: "0px" }}
            textAlign={{ lg: "center", md: "center", base: "start" }}
            direction={{ lg: "row", md: "row", base: "column" }}
          >
            <Box>
              <Datum label="Individual Providers" value={providersCount} />
            </Box>
            <Box>
              <Datum label="County" value={county} />
            </Box>
            {customerIdent()}
            <SuggestedActionTag suggestedAction={suggestedAction} />
          </Flex>
        </Flex>
        <Flex direction="column">
          <Flex>
            <Flex
              basis="45%"
              gap="12px"
              direction="column"
              p="20px"
              w="100%"
              left="480px"
              right="648px"
              borderRight="1px"
              borderRightColor="gray.300"
            >
              <Flex
                flex="1"
                justifyContent="space-between"
                direction={{ lg: "row", md: "column", base: "column" }}
              >
                <Box w="100%">
                  <Datum
                    label={
                      <FormLabel fontWeight="normal" fontSize="sm" htmlFor="providerSpecialty">
                        Credit/Debit to MRS
                      </FormLabel>
                    }
                    value={
                      <Flex direction="column" alignItems="flex-start">
                        <Flex direction={{ lg: "row", md: "column", base: "column" }}>
                          <PercentInput
                            isLocked={isLocked}
                            isLoading={isLoadingToggle || isLoading}
                            isDisabled={isLoadingToggle}
                            id="providerSpecialty"
                            size="md"
                            mt="6px"
                            p="8px"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setAdjustmentValue(e.target.value)
                            }
                            value={adjustmentValue || "0"}
                          />
                          <Button
                            w="100%"
                            aria-label="Apply adjustment change"
                            bgColor="indigo.100"
                            color="indigo"
                            variant="solid"
                            fontSize="sm"
                            onClick={handleSaveAdjustment}
                            isLoading={isLoading}
                            loadingText="Updating..."
                            data-testid="apply-adjustment-button"
                            style={{
                              opacity: parseInt(adjustmentValue || "") !== adjustment ? 1 : 0,
                            }}
                          >
                            Apply to all
                          </Button>
                        </Flex>
                        {useCalculatedSrfAdjustment === false ? (
                          <Button
                            className="fade-in"
                            id="revert-to-default-button"
                            onClick={handleSetUseCalculatedSrfAdjustment}
                            colorScheme="indigo"
                            size="sm"
                            variant="link"
                            leftIcon={<RefreshIcon isAnimating={isLoading} />}
                          >
                            Revert to default
                          </Button>
                        ) : (
                          <Box h="34px" />
                        )}
                      </Flex>
                    }
                  />
                </Box>
                <Box w="60%" textAlign={{ lg: "right", md: "left", base: "left" }}>
                  <Datum
                    label="Average MRS"
                    value={
                      <Box p="8px" textAlign={{ lg: "right", base: "left" }}>
                        <Rolodex num={averageMRS} />
                      </Box>
                    }
                  />
                </Box>
              </Flex>
              <Flex w="100%" direction={{ lg: "row", md: "column", base: "column" }}>
                <Datum
                  label="Booked SRF"
                  value={
                    <Flex>
                      <Rolodex num={bookedSRFReadable} />%
                    </Flex>
                  }
                />
                <Box textAlign={{ lg: "right", md: "left", base: "left" }}>
                  <Datum
                    label="Average Adjusted MRS"
                    value={<Rolodex num={averageAdjustedMrs} />}
                  />
                </Box>
              </Flex>
              <Flex
                w="100%"
                direction={{ lg: "row", md: "column", base: "column" }}
                justifyContent={{ lg: "flex-end", md: "start", base: "start" }}
              >
                <Datum
                  label="Indigo Premium"
                  value={
                    <Flex>
                      <Text>{indigoPremium || "None"}</Text>
                      {hasStalePricing && (
                        <Tooltip hasArrow={true} label="Pricing data may be incorrect">
                          <Flex>
                            <ExpiredIcon />
                          </Flex>
                        </Tooltip>
                      )}
                    </Flex>
                  }
                />
                <Box textAlign={{ lg: "right", md: "left", base: "left" }}>
                  {targetPremium && <Datum label="Target Premium" value={targetPremium} />}
                </Box>
              </Flex>
            </Flex>
            <Flex basis="55%" w="100%" justifyContent="space-between" p="24px">
              <Flex flex="2" direction="column">
                <GroupCardSummary
                  hasLargeGroup={providersCount > 1}
                  averageMRS={averageMRS}
                  mrsReadable={mrsReadable}
                />
                {summaryText && (
                  <Flex padding="16px" direction="column">
                    <Text color="black" fontWeight="bold" fontSize="sm">
                      Medical Practice Summary
                    </Text>
                    <Text fontWeight="normal" lineHeight="none" fontSize="sm">
                      {summaryText}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}
