import { createContext } from "react";

type SearchPollContextProps = {
  isPollingSearchEvents: boolean;
  pollingProviderIds: string[];
};

export const SearchPollingContext = createContext<SearchPollContextProps>({
  isPollingSearchEvents: false,
  pollingProviderIds: [],
});
