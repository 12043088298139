export var CARRIERS = [
    {
        id: "8e58f14c-4743-45eb-b183-8b13c5b8c396",
        createdAt: "2024-07-30T04:48:36.492Z",
        name: "Bare",
        updatedAt: "2024-07-30T04:48:36.492Z",
    },
    {
        id: "c049ba1f-e849-46f4-a385-fcd6cafa1e4c",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "Physicians Insurance",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "8b4f02b7-1afc-4e92-9fa0-18b61dd287d8",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "TMLT",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "a23b977a-c3e5-4b28-b425-bb207b3cb3b6",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "Coverys",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "b9261941-b3ae-4594-975f-fcd8d474d4c0",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "Aspen",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "6bb44bcd-ac22-4a74-a02a-074d256de08f",
        createdAt: "2023-09-29T04:48:36.491Z",
        name: "NORCAL",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "ba819bac-800f-45e4-8c55-ecf1fb38f2ba",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "Med Pro",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "d18edcca-abd4-4bed-8d2e-d31fac5005ae",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "PSIC",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "b34e4429-3092-43b0-a4e9-5ba7881fd176",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "TDC",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "39b13f89-a14c-4465-8395-e05bc0355638",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "Lonestar",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "a3dbca4a-40ef-4670-8aab-b3fb53c66dc4",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "Doctors Direct",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "057068ea-6b30-4934-88d4-38b45da01cff",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "AMS",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "7bfca454-90b2-48d3-8b8c-75147c729d41",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "Mag Mutual",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "27750973-8bf9-4808-b6e5-3fb482ed8d15",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "Medchoice RRG",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "a188b6fa-eb12-49e5-9ced-14f3baf12393",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "ISMIE",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "fe94b614-67b0-48c9-8f15-c04ae30870f1",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "Curi",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "74c96c23-9c85-48ff-b751-fb44b534953e",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "Positive Physicians",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "04ce6dd8-05f7-40bc-bdaa-b908bf2267af",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "ProAssurance",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "02c49d42-1cd6-4a8a-9137-6c7b1d90d31a",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "Other",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "df72b1ca-400f-47c5-a1e1-ca7146325033",
        createdAt: "2023-09-29T04:48:36.492Z",
        name: "New to Practice",
        updatedAt: "2023-09-29T04:48:36.492Z",
    },
    {
        id: "f3a1b8c7-d2e4-4f5a-9c6b-8d7e0f1g2h3i",
        createdAt: "2024-10-02T12:00:00.000Z",
        name: "SVMIC",
        updatedAt: "2024-10-02T12:00:00.000Z",
    },
    {
        id: "j4k5l6m7-n8o9-p0q1-r2s3-t4u5v6w7x8y9",
        createdAt: "2024-10-02T12:00:00.000Z",
        name: "MLMIC",
        updatedAt: "2024-10-02T12:00:00.000Z",
    },
    {
        id: "a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p6",
        createdAt: "2024-10-02T12:00:00.000Z",
        name: "EmPro",
        updatedAt: "2024-10-02T12:00:00.000Z",
    },
    {
        id: "q7r8s9t0-u1v2-w3x4-y5z6-a7b8c9d0e1f2",
        createdAt: "2024-10-02T12:00:00.000Z",
        name: "CAP MPT",
        updatedAt: "2024-10-02T12:00:00.000Z",
    },
    {
        id: "g3h4i5j6-k7l8-m9n0-p1q2-r3s4t5u6v7w8",
        createdAt: "2024-10-02T12:00:00.000Z",
        name: "COPIC",
        updatedAt: "2024-10-02T12:00:00.000Z",
    },
    {
        id: "x9y0z1a2-b3c4-d5e6-f7g8-h9i0j1k2l3m4",
        createdAt: "2024-10-02T12:00:00.000Z",
        name: "MICA",
        updatedAt: "2024-10-02T12:00:00.000Z",
    },
    {
        id: "n5o6p7q8-r9s0-t1u2-v3w4-x5y6z7a8b9c0",
        createdAt: "2024-10-02T12:00:00.000Z",
        name: "Integris",
        updatedAt: "2024-10-02T12:00:00.000Z",
    },
    {
        id: "d1e2f3g4-h5i6-j7k8-l9m0-n1o2p3q4r5s6",
        createdAt: "2024-10-02T12:00:00.000Z",
        name: "MIEC",
        updatedAt: "2024-10-02T12:00:00.000Z",
    },
    {
        id: "t7u8v9w0-x1y2-z3a4-b5c6-d7e8f9g0h1i2",
        createdAt: "2024-10-02T12:00:00.000Z",
        name: "Doctors Professional RRG",
        updatedAt: "2024-10-02T12:00:00.000Z",
    },
    {
        id: "j3k4l5m6-n7o8-p9q0-r1s2-t3u4v5w6x7y8",
        createdAt: "2024-10-02T12:00:00.000Z",
        name: "CARE RRG",
        updatedAt: "2024-10-02T12:00:00.000Z",
    },
    {
        id: "z9a0b1c2-d3e4-f5g6-h7i8-j9k0l1m2n3o4",
        createdAt: "2024-10-02T12:00:00.000Z",
        name: "MedMal Direct",
        updatedAt: "2024-10-02T12:00:00.000Z",
    },
    {
        id: "p5q6r7s8-t9u0-v1w2-x3y4-z5a6b7c8d9e0",
        createdAt: "2024-10-02T12:00:00.000Z",
        name: "Medical Mutual of Maryland",
        updatedAt: "2024-10-02T12:00:00.000Z",
    },
];
