import {
  Text,
  Flex,
  Grid,
  GridItem,
  Card,
  CardBody,
  CardHeader,
  useMediaQuery,
} from "@chakra-ui/react";
import { Trend } from "components";
import { InsightDetailType } from "localTypes";
import { InputMaybe, MidlevelUpdateInput } from "__generated__/graphql";
import { MIDLEVEL_SUFFIXES } from "app-constants";

export type MidlevelRenewalFlags = {
  providerId: string;
  flags: InsightDetailType[];
};

export interface MidlevelRenewalRowProps {
  isLargerThan900: boolean;
  mrs: number;
  midlevel: MidlevelProviderRenewal;
  direction: "up" | "down" | "none";
  specialty: string;
}

export function MidlevelRenewalRow({
  direction,
  midlevel,
  isLargerThan900,
  mrs,
  specialty = "hello",
}: MidlevelRenewalRowProps) {
  return (
    <Grid
      templateColumns="repeat(100, 1fr)"
      h="54px"
      _hover={{
        backgroundColor: "indigo.100",
      }}
      borderBottom="1px solid"
      borderColor="gray.200"
      fontSize={isLargerThan900 ? "sm" : "xs"}
    >
      <GridItem pt="12px" pl={2} borderRight="1px solid" borderColor="gray.300" colSpan={20}>
        <Text isTruncated>
          {midlevel.firstName} {midlevel.lastName}
          {midlevel?.suffix ? `, ${MIDLEVEL_SUFFIXES[midlevel.suffix || ""]}` : ""}
        </Text>
      </GridItem>
      <GridItem
        pt="12px"
        borderRight="1px solid"
        borderColor="gray.300"
        colSpan={37}
        textAlign="center"
        justifyContent="center"
      >
        <Text>{specialty}</Text>
      </GridItem>
      <GridItem
        pt="12px"
        borderRight="1px solid"
        borderColor="gray.300"
        colSpan={8}
        textAlign="center"
      >
        {midlevel.oldMrs?.toFixed(2)}
      </GridItem>
      <GridItem
        pt="12px"
        borderRight="1px solid"
        borderColor="gray.300"
        colSpan={8}
        textAlign="center"
        justifyContent="center"
      >
        <Flex justifyContent="center">
          <Trend value={Math.round((mrs || 0) * 100) / 100} direction={direction} />
        </Flex>
      </GridItem>
      <GridItem
        pt="12px"
        borderRight="1px solid"
        borderColor="gray.300"
        colSpan={11}
        textAlign="center"
      >
        {midlevel.oldPricing}
      </GridItem>
      <GridItem
        pt="12px"
        borderRight="1px solid"
        borderColor="gray.300"
        colSpan={16}
        textAlign="center"
      >
        {midlevel.pricing}
      </GridItem>
    </Grid>
  );
}

export type MidlevelProviderRenewal = Pick<
  MidlevelUpdateInput,
  "id" | "firstName" | "lastName" | "suffix"
> & {
  mrs?: InputMaybe<number>;
  oldMrs?: InputMaybe<number>;
  pricing: string;
  oldPricing: string;
  specialty: string;
};

export interface MidlevelRenewalTableProps {
  midlevels?: MidlevelProviderRenewal[];
}
export function MidlevelRenewalTable({ midlevels }: MidlevelRenewalTableProps) {
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  return (
    <>
      <Card mt="32px" whiteSpace="nowrap" minWidth="700px" overflowX="visible">
        <CardHeader
          p="0"
          borderTopRightRadius="3px"
          borderTopLeftRadius="3px"
          bg="indigo.300"
          color="white"
          fontWeight="bold"
          fontSize={isLargerThan900 ? "sm" : "xs"}
          textAlign="center"
        >
          <Grid templateColumns="repeat(100, 1fr)" h="40px">
            <GridItem
              pt={1}
              borderRight="1px solid"
              borderColor="gray.300"
              colSpan={20}
              alignItems="start"
              textAlign="left"
              pl={2}
            >
              Non-Physician Provider
            </GridItem>
            <GridItem pt={1} borderRight="1px solid" borderColor="gray.300" colSpan={37}>
              Specialty
            </GridItem>
            <GridItem pt={1} borderRight="1px solid" borderColor="gray.300" colSpan={8}>
              MRS Old
            </GridItem>
            <GridItem pt={1} borderRight="1px solid" borderColor="gray.300" colSpan={8}>
              MRS New
            </GridItem>
            <GridItem pt={1} borderRight="1px solid" borderColor="gray.300" colSpan={11}>
              Pricing Old
            </GridItem>
            <GridItem pt={1} borderRight="1px solid" borderColor="gray.300" colSpan={16}>
              Pricing New
            </GridItem>
          </Grid>
        </CardHeader>

        <CardBody p="0">
          {midlevels?.map((midlevel, index) => {
            const oldMrs = midlevel.oldMrs || 0;
            const newMrs = midlevel.mrs || 0;
            const direction = newMrs > oldMrs ? "up" : newMrs < oldMrs ? "down" : "none";
            return (
              <MidlevelRenewalRow
                specialty={midlevel.specialty || ""}
                mrs={newMrs}
                direction={direction}
                isLargerThan900={isLargerThan900}
                midlevel={midlevel}
                key={index}
              />
            );
          })}
        </CardBody>
      </Card>
    </>
  );
}
