import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Container,
  Center,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useAnalytics } from "providers";
import { Slime } from "components";
import { useMutation } from "@apollo/client";
import { SAVE_SUBMISSION } from "mutations";
import { GET_SUBMISSION } from "queries";
import { generateId } from "utils";
import { RAT_EVENTS } from "analytics";
import { StatusType } from "__generated__/graphql";
import { useLocalAuthSession } from "hooks";

export function NewInquiry() {
  const currentUser = useLocalAuthSession();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const [isLoading, setIsLoading] = useState(false);
  const [mutateFunction] = useMutation(SAVE_SUBMISSION, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const navigate = useNavigate();
  const { track } = useAnalytics();

  return (
    <Modal
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      onClose={() => null}
      size="full"
      isOpen={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="end">
            <Button variant="ghost" color="indigo" onClick={() => signOut()} w="132px">
              Sign Out
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody overflow="visible">
          <Container mt="150px" alignItems="center">
            <Flex direction="column">
              <Box>
                <Center>
                  <Image
                    mb="45px"
                    h="45px"
                    src="https://uploads-ssl.webflow.com/645029572ba4d37613789f79/645029572ba4d36e42789f80_230426_Indigo_Logo_Primary-p-1080.png"
                    alt="Indigo Logo"
                  />
                </Center>
              </Box>
              <Text data-testid="new-inquiry-text" align="center" fontSize="xl">
                Receive a quote assessment in a few easy steps.
                <br />A quick and simple way to gauge risk and qualification!
              </Text>
              <Text align="center" mt="60px">
                To get started select an Individual or Multiple Providers
              </Text>
              <Center>
                <ButtonGroup colorScheme="indigo">
                  <Button
                    isLoading={isLoading}
                    onClick={async () => {
                      try {
                        setIsLoading(true);
                        track(RAT_EVENTS.SELECT_SINGLE_PROVIDER_FLOW);
                        const id = generateId();
                        await mutateFunction({
                          variables: {
                            input: {
                              id,
                              status: StatusType.Ratwip,
                              providers: [{ id: generateId() }],
                              producer: {
                                id: generateId(),
                                email: currentUser?.email,
                              },
                            },
                          },
                        });
                        setIsLoading(false);
                        navigate(`/broker/inquiry/${id}/provider-info`);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                    w="132px"
                  >
                    Individual
                  </Button>
                  <Button
                    isLoading={isLoading}
                    onClick={async () => {
                      try {
                        setIsLoading(true);
                        track(RAT_EVENTS.SELECT_SINGLE_PROVIDER_FLOW);
                        const id = generateId();
                        await mutateFunction({
                          variables: {
                            input: {
                              id,
                              status: StatusType.Ratwip,
                              providers: [{ id: generateId() }],
                              producer: {
                                id: generateId(),
                                email: currentUser?.email,
                              },
                            },
                          },
                        });
                        setIsLoading(false);
                        navigate(`/broker/inquiry/${id}/provider-info`);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                    w="132px"
                  >
                    Multiple
                  </Button>
                </ButtonGroup>
              </Center>
            </Flex>
          </Container>
        </ModalBody>
        <Slime />
      </ModalContent>
    </Modal>
  );
}
