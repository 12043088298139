import { useEffect, ChangeEvent, useState } from "react";
import { RefreshIcon } from "assets";
import { Button, Flex, Grid, GridItem, Card, CardBody, Text } from "@chakra-ui/react";
import { PercentInput, Trend, Rolodex } from "components";
import { InputMaybe } from "__generated__/graphql";

export interface RenewalSummaryProps {
  entityName: string;
  srf: number;
  oldMrs: number;
  newMrs: number;
  oldPricing: string;
  newPricing: string;
  isEntity: boolean;
  isLocked?: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  adjustment?: InputMaybe<number>;
  handleSaveAdjustment: (adjustment: number) => void;
  useCalculatedSrfAdjustment: boolean;
  calculatedSrfAdjustment: number;
}

export function RenewalSummary({
  newMrs,
  oldMrs,
  entityName,
  srf,
  newPricing,
  oldPricing,
  isEntity,
  isLocked,
  isLoading,
  isDisabled,
  adjustment,
  handleSaveAdjustment,
  useCalculatedSrfAdjustment,
  calculatedSrfAdjustment,
}: RenewalSummaryProps) {
  const [adjustmentValue, setAdjustmentValue] = useState<string | undefined>(`${adjustment}`);
  const direction: "up" | "down" | "none" =
    newMrs === oldMrs ? "none" : newMrs > oldMrs ? "up" : "down";

  useEffect(() => {
    setAdjustmentValue(`${adjustment}`);
  }, [adjustment]);

  return (
    <Card w="100%">
      <CardBody p="8px" pb="0">
        <Grid templateColumns="repeat(100, 1fr)">
          <GridItem colSpan={20}>
            <Flex direction="column">
              <Text fontSize="sm" fontWeight="normal">
                {isEntity ? "Entity Name" : "Group Summary"}
              </Text>
              <Text lineHeight="none" fontSize="sm" fontWeight="bold">
                {entityName}
              </Text>
            </Flex>
          </GridItem>
          <GridItem colSpan={25}>
            <Flex direction="column">
              <Text fontSize="sm" fontWeight="normal">
                Credit/Debit to MRS
              </Text>
              <Flex direction="row">
                <PercentInput
                  isLocked={isLocked}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  id="providerSpecialty"
                  size="md"
                  mt="6px"
                  px="8px"
                  h="36px"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAdjustmentValue(e.target.value)
                  }
                  value={adjustmentValue}
                />
                <Button
                  aria-label="Apply adjustment change"
                  bgColor="indigo.100"
                  color="indigo"
                  variant="solid"
                  fontSize="sm"
                  onClick={() => handleSaveAdjustment(parseInt(adjustmentValue || "0"))}
                  style={{
                    opacity: parseInt(adjustmentValue || "") !== adjustment ? 1 : 0,
                  }}
                >
                  Apply
                </Button>
              </Flex>
              <Flex>
                {useCalculatedSrfAdjustment === false && (
                  <Button
                    mt="0"
                    className="fade-in"
                    id="revert-to-default-button"
                    onClick={() => handleSaveAdjustment(calculatedSrfAdjustment)}
                    colorScheme="indigo"
                    size="sm"
                    variant="link"
                    leftIcon={<RefreshIcon isAnimating={isLoading} />}
                  >
                    Revert to default
                  </Button>
                )}
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={3} />
          <GridItem colSpan={9}>
            <Flex direction="column" alignItems="center" fontSize="sm">
              <Text fontSize="sm" fontWeight="normal">
                Booked SRF
              </Text>
              <div style={{ whiteSpace: "nowrap" }}>
                <Text fontSize="sm" fontWeight="bold">
                  <Rolodex num={Math.round(srf * 100) / 100} />%
                </Text>
              </div>
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex direction="column" alignItems="center">
              <Text fontSize="sm" fontWeight="normal">
                MRS Old
              </Text>
              <Text fontSize="sm" fontWeight="bold">
                <Rolodex num={Math.round(oldMrs * 100) / 100} />
              </Text>
            </Flex>
          </GridItem>
          <GridItem colSpan={8}>
            <Flex direction="column" alignItems="center">
              <Text fontSize="sm" fontWeight="normal">
                MRS New
              </Text>
              <Trend value={Math.round(newMrs * 100) / 100} direction={direction} />
            </Flex>
          </GridItem>
          <GridItem colSpan={11}>
            <Flex direction="column" alignItems="center">
              <Text fontSize="sm" fontWeight="normal">
                Pricing Old
              </Text>
              <Text fontSize="sm" fontWeight="bold">
                {oldPricing}
              </Text>
            </Flex>
          </GridItem>
          <GridItem colSpan={11}>
            <Flex direction="column" alignItems="center">
              <Text fontSize="sm" fontWeight="normal">
                Pricing New
              </Text>
              <Text fontSize="sm" fontWeight="bold">
                {newPricing}
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
}
