import { Container, Image } from "@chakra-ui/react";
import logo from "logo.svg";
import "./loader.css";

export function Loader() {
  return (
    <Container data-testid="loading-spinner" p={100}>
      <Image
        m="2px auto"
        alt="logo loading spinner"
        className="logo-spinner"
        boxSize="40px"
        color="red"
        src={logo}
      />
    </Container>
  );
}
