import {
  Stat,
  StatLabel,
  StatNumber,
  StatArrow,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Text,
} from "@chakra-ui/react";
import { Maybe, BillingNode, MrsTrend } from "__generated__/graphql";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { Datum, RangeDisplay, CardAction } from "components";
import { formatAsDollar } from "utils";
import { useAppetite } from "providers";
import { AVERAGE_MRS_QUOTE_THRESHOLD, AVERAGE_MRS_QUOTE_THRESHOLD_LARGE } from "app-constants";

export interface ConfidenceProps {
  mrsTrend?: Maybe<Maybe<MrsTrend>[]>;
  mrs: number;
  payerData: Maybe<Maybe<BillingNode>[]>;
  hvData: Maybe<Maybe<BillingNode>[]>;
  hasLargeGroup: boolean;
  id: string;
}
export function Confidence({
  id,
  hvData,
  mrsTrend,
  payerData,
  mrs,
  hasLargeGroup,
}: ConfidenceProps) {
  const appetite = useAppetite();
  const providerAppetite = appetite?.providers?.find((provider) => provider.id === id);
  const threshold = hasLargeGroup ? AVERAGE_MRS_QUOTE_THRESHOLD_LARGE : AVERAGE_MRS_QUOTE_THRESHOLD;
  const isInAdjustedMeansTable = providerAppetite?.messages
    ?.map((m) => m.text)
    ?.includes("In Adjusted Means");
  const hasLowBillingConfidence = !providerAppetite?.messages
    ?.map((m) => m.text)
    ?.includes("High Billing Confidence");
  return (
    <Flex w="100%" gap="16px" direction="column">
      <Flex gap="8px">
        <Card w="100%" p="16px">
          <CardHeader p="0" mb="8px">
            <Text fontSize="sm" fontWeight="bold" lineHeight="115%">
              MRS
            </Text>
            <Text fontSize="xs" fontWeight="medium" color="gray.500">
              Calculated MRS
            </Text>
          </CardHeader>
          <CardBody p="0">
            <Datum
              label={
                <Text fontSize="2rem" color="gray.500" mb="8px">
                  {mrs}
                </Text>
              }
              value={<RangeDisplay threshold={threshold} value={mrs} min={0} max={2} />}
            />
          </CardBody>
        </Card>
        <Card w="100%" p="16px">
          <CardHeader p="0" mb="8px" fontWeight="bold" lineHeight="115%">
            Confidence Summary
          </CardHeader>
          <CardBody p="0">
            <Flex w="100%" gap="8px" alignItems="start">
              {isInAdjustedMeansTable ? (
                <CardAction message="In Adjusted Means Table" variant="success" />
              ) : (
                <CardAction message="Not In Adjusted Means Table" variant="error" />
              )}
            </Flex>
            <Flex w="100%" gap="8px" alignItems="center">
              {!hasLowBillingConfidence ? (
                <CardAction message="High Billing Confidence" variant="success" />
              ) : (
                <CardAction message="Low Billing Confidence" variant="error" />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      <Card w="100%" p="16px">
        <CardHeader lineHeight="115%" fontWeight="bold" p="0" mb="8px">
          MRS Trend
        </CardHeader>
        <CardBody p="0">
          <Flex w="100%" justifyContent="space-between" gap="12px">
            {mrsTrend?.map((trend, index) => {
              const previousValue: Maybe<number> | undefined = mrsTrend[index + 1]?.value;
              return (
                <Card
                  w="100%"
                  border="1px solid"
                  borderColor="gray.200"
                  boxShadow="none"
                  key={index}
                >
                  <CardBody p="16px">
                    <Stat>
                      <StatLabel>
                        <Text fontWeight="bold" fontSize="xs" color="gray.450">
                          {trend?.version}
                        </Text>
                      </StatLabel>
                      <StatNumber>
                        <Flex alignItems="center" gap="8px">
                          {previousValue === undefined ||
                          previousValue === null ||
                          previousValue === -1 ? null : previousValue < (trend?.value || -1) ? (
                            <StatArrow
                              data-testid="increase-arrow-1"
                              color="red"
                              type="increase"
                              fontSize="0.75rem"
                            />
                          ) : (
                            <StatArrow
                              data-testid="decrease-arrow-1"
                              color="green"
                              type="decrease"
                              fontSize="0.75rem"
                            />
                          )}
                          <Text fontSize="lg">
                            {(trend?.value || -1) === -1 ? "No Data" : trend?.value?.toFixed(2)}
                          </Text>
                        </Flex>
                      </StatNumber>
                    </Stat>
                  </CardBody>
                </Card>
              );
            })}
          </Flex>
        </CardBody>
      </Card>
      <Flex gap="8px">
        <Card w="100%" p="16px">
          <CardHeader p="0" mb="8px" fontWeight="bold" lineHeight="115%">
            Billing Data
          </CardHeader>
          <CardBody p="0">
            <Flex gap="12px" w="100%" direction="column">
              <Flex w="100%" direction="column">
                <Text fontSize="sm" mb="8px" fontWeight="normal" color="gray.500">
                  Payer Data
                </Text>
                <Flex gap="8px" w="100%" justifyContent="space-between">
                  {payerData?.length === 0 ? (
                    <Flex w="100%" gap="8px" alignItems="center">
                      <WarningTwoIcon color="red.500" fontSize="1.25rem" />
                      <Text fontSize="lg" color="gray.500" fontWeight="bold">
                        No Data
                      </Text>
                    </Flex>
                  ) : (
                    payerData?.map((payer, index) => {
                      return (
                        <Card
                          key={index}
                          w="100%"
                          border="1px solid"
                          borderColor="gray.200"
                          boxShadow="none"
                        >
                          <CardBody p="16px">
                            <Flex direction="column" gap="8px">
                              <Text fontSize="xs" fontWeight="bold" color="gray.450">
                                {payer?.year}
                              </Text>
                              {!payer?.amount ||
                              payer?.amount === "null" ||
                              payer?.amount === "undefined" ? (
                                <Flex w="100%" gap="8px" alignItems="center">
                                  <WarningTwoIcon color="red.500" fontSize="1.25rem" />
                                  <Text fontSize="lg" color="gray.500" fontWeight="bold">
                                    No Data
                                  </Text>
                                </Flex>
                              ) : (
                                <Text fontSize="lg" color="gray.500" fontWeight="bold">
                                  {formatAsDollar.format(parseFloat(payer?.amount))}
                                </Text>
                              )}
                            </Flex>
                          </CardBody>
                        </Card>
                      );
                    })
                  )}
                </Flex>
              </Flex>
              <Flex w="100%" direction="column">
                <Text fontSize="sm" mb="8px" fontWeight="normal" color="gray.500">
                  HealthVerity
                </Text>
                <Flex gap="8px" w="100%" justifyContent="space-between">
                  {hvData?.length === 0 ? (
                    <Flex w="100%" gap="8px" alignItems="center">
                      <WarningTwoIcon color="red.500" fontSize="1.25rem" />
                      <Text fontSize="lg" color="gray.500" fontWeight="bold">
                        No Data
                      </Text>
                    </Flex>
                  ) : (
                    hvData?.map((hv, index) => {
                      return (
                        <Card
                          key={index}
                          w="100%"
                          border="1px solid"
                          borderColor="gray.200"
                          boxShadow="none"
                        >
                          <CardBody p="16px">
                            <Flex direction="column" gap="8px">
                              <Text fontSize="xs" fontWeight="bold" color="gray.450">
                                {hv?.year}
                              </Text>
                              {!hv?.amount ||
                              hv?.amount === "null" ||
                              hv?.amount === "undefined" ? (
                                <Flex w="100%" gap="8px" alignItems="center">
                                  <WarningTwoIcon color="red.500" fontSize="1.25rem" />
                                  <Text fontSize="lg" color="gray.500" fontWeight="bold">
                                    No Data
                                  </Text>
                                </Flex>
                              ) : (
                                <Flex alignItems="center" gap="4px">
                                  <Text fontSize="lg" color="gray.500" fontWeight="bold">
                                    {parseFloat(hv?.amount).toFixed(0)}
                                  </Text>
                                  <Text fontWeight="normal">Bills</Text>
                                </Flex>
                              )}
                            </Flex>
                          </CardBody>
                        </Card>
                      );
                    })
                  )}
                </Flex>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </Flex>
  );
}
