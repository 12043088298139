import { useState } from "react";
import { Spinner, Flex, FormControl, FormLabel, Switch, Icon, Tooltip } from "@chakra-ui/react";
import { colors } from "theme/colors";
import { HiPencil } from "react-icons/hi";
import { BsLightningChargeFill } from "react-icons/bs";

export interface QuickAuditToggleProps {
  isChecked: boolean;
  isDisabled: boolean;
  handleToggle: () => Promise<void>;
}

export function QuickAuditToggle({ isDisabled, isChecked, handleToggle }: QuickAuditToggleProps) {
  const [isToggling, setIsToggling] = useState(false);
  return (
    <Tooltip label={isDisabled ? "Make changes to enable" : ""}>
      <FormControl w="auto" display="flex" alignItems="center">
        <FormLabel htmlFor="email-alerts" mb="4px">
          {isChecked ? "Defaults" : "Manual Edits"}
        </FormLabel>
        <Flex alignItems="center">
          {isToggling ? (
            <Icon
              data-testid="spinner-icon"
              style={{ pointerEvents: "none" }}
              as={Spinner}
              color="indigo.500"
              fontSize="1rem"
              mr="-21px"
              zIndex="11"
            />
          ) : (
            <Icon
              style={{ pointerEvents: "none" }}
              data-testid="lightning-icon"
              as={BsLightningChargeFill}
              color="indigo.500"
              fontSize="1rem"
              mr="-21px"
              zIndex="11"
            />
          )}
          <Switch
            isDisabled={isDisabled}
            data-testid="quick-audit-toggle"
            onChange={async () => {
              try {
                setIsToggling(true);
                await handleToggle();
              } catch (e) {
                console.error(e);
              } finally {
                setIsToggling(false);
              }
            }}
            isChecked={!isChecked}
            size="lg"
            colorScheme="indigo"
          />
          {isToggling ? (
            <Icon
              style={{
                color: isChecked ? "transparent" : colors.indigo[500],
                pointerEvents: "none",
              }}
              as={Spinner}
              color="indigo.500"
              fontSize="1rem"
              ml="-21.5px"
              zIndex="11"
            />
          ) : (
            <Icon
              style={{
                color: isChecked ? "transparent" : colors.indigo[500],
                pointerEvents: "none",
              }}
              data-testid="pencil-icon"
              as={HiPencil}
              color="indigo.500"
              fontSize="1rem"
              ml="-21.5px"
              zIndex="11"
            />
          )}
        </Flex>
      </FormControl>
    </Tooltip>
  );
}
