import { useState, useEffect, ChangeEvent } from "react";
import {
  UseFormSetValue,
  FieldErrors,
  UseFormRegister,
  Controller,
  Control,
  UseFormClearErrors,
} from "react-hook-form";
import {
  Checkbox,
  Grid,
  GridItem,
  Box,
  Select,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";

import { STATES, INDIGO_SPECIALTIES } from "app-constants";
import { AddressOption, DatePicker, AddressInputField } from "components";
import { formatLimit, addressToString } from "utils";
import {
  Limit,
  LimitType,
  InputMaybe,
  AddressInput,
  SubmissionUpdateInput,
} from "__generated__/graphql";

export interface SubmissionFormProps {
  control: Control<SubmissionUpdateInput>;
  setValue?: UseFormSetValue<SubmissionUpdateInput>;
  address?: InputMaybe<AddressInput>;
  index: number;
  register: UseFormRegister<SubmissionUpdateInput>;
  errors?: FieldErrors<SubmissionUpdateInput>;
  onChangeSelect?: (e: ChangeEvent<HTMLSelectElement>) => void;
  clearErrors: UseFormClearErrors<SubmissionUpdateInput>;
}
export function SubmissionForm({
  address,
  onChangeSelect,
  errors,
  control,
  register,
  index,
  setValue,
  clearErrors,
}: SubmissionFormProps) {
  const e = errors?.providers?.[index];
  const [selectedAddress, setSelectedAddress] = useState<AddressOption | null>(() => {
    if (address) {
      return {
        label: addressToString(address),
        value: address,
      };
    }
    return null;
  });

  useEffect(() => {
    if (selectedAddress === null && address) {
      setSelectedAddress({
        label: addressToString(address),
        value: address,
      });
    }
  }, [selectedAddress, address, setSelectedAddress]);
  return (
    <>
      <Flex gap="12px">
        <FormControl isInvalid={!!e?.firstName?.message}>
          <FormLabel
            htmlFor={`providers.${index}.firstName`}
            fontSize="md"
            mb="0px"
            fontWeight="normal"
          >
            First Name *
          </FormLabel>
          <Input
            id={`providers.${index}.firstName`}
            type="text"
            {...register(`providers.${index}.firstName`, {
              required: "First name is required",
            })}
          />
          <FormErrorMessage>{e?.firstName?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!e?.lastName?.message}>
          <FormLabel
            htmlFor={`providers.${index}.lastName`}
            fontSize="md"
            mb="0px"
            fontWeight="normal"
          >
            Last Name *
          </FormLabel>
          <Input
            id={`providers.${index}.lastName`}
            type="text"
            {...register(`providers.${index || 0}.lastName`, {
              required: "Last name is required",
            })}
          />
          <FormErrorMessage>{e?.lastName?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
      <Grid
        gap="12px"
        templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
      >
        <GridItem colSpan={9} mt="22px">
          <FormControl isInvalid={!!e?.indigoSpecialty?.message}>
            <FormLabel
              htmlFor={`providers.${index}.indigoSpecialty`}
              fontSize="md"
              mb="0px"
              fontWeight="normal"
            >
              Indigo Specialty *
            </FormLabel>
            <Select
              id={`providers.${index}.indigoSpecialty`}
              size="md"
              m="0px"
              p="0px"
              {...register(`providers.${index}.indigoSpecialty`, {
                required: "Specialty is required",
              })}
              onChange={onChangeSelect || register(`providers.${index}.indigoSpecialty`).onChange}
            >
              <option></option>
              {INDIGO_SPECIALTIES.map((spec) => (
                <option key={spec.value} value={spec.name}>
                  {spec.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{e?.indigoSpecialty?.message}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <Box mt="14px">
            <Controller
              control={control}
              name={`providers.${index}.npi`}
              render={({ fieldState, field }) => {
                return (
                  <FormControl isInvalid={Boolean(fieldState.error)}>
                    <FormLabel htmlFor={`providers.${index}.npi`}>NPI</FormLabel>
                    <Input
                      id={`providers.${index}.npi`}
                      placeholder="NPI"
                      onChange={field.onChange}
                      value={`${field.value}`}
                    />
                    <FormErrorMessage>
                      {fieldState.error && fieldState.error.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
        </GridItem>
      </Grid>
      <Flex gap="12px" direction={["column", "row"]}>
        <Box mt="22px" flex="3">
          {selectedAddress && (
            <Grid
              gap="12px"
              templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
            >
              <GridItem colSpan={3}>
                <Controller
                  rules={{ required: "Address is required" }}
                  render={({ fieldState }) => (
                    <FormControl isInvalid={Boolean(fieldState.error)}>
                      <FormLabel
                        htmlFor={`providers.${index}.address.streetName`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                      >
                        Address *
                      </FormLabel>
                      <AddressInputField
                        isInvalid={Boolean(fieldState.error)}
                        id={`providers.${index}.address.streetName`}
                        onChange={(option: AddressOption | null) => {
                          clearErrors(`providers.${index}.address`);
                          setSelectedAddress(option);
                          setValue?.(`providers.${index}.address`, option?.value || null);
                          setValue?.(`providers.${index}.address.city`, option?.value?.city || "");
                          setValue?.(
                            `providers.${index}.address.state`,
                            option?.value?.state || "",
                          );
                          setValue?.(`providers.${index}.address.zip`, option?.value?.zip || "");
                          setValue?.(
                            `providers.${index}.address.secondary`,
                            option?.value?.secondary || "",
                          );
                          setValue?.(
                            `providers.${index}.address.streetName`,
                            option?.value?.streetName || "",
                          );
                        }}
                        value={selectedAddress}
                      />
                      <FormErrorMessage>
                        <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                        {fieldState.error && fieldState.error.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  control={control}
                  name={`providers.${index}.address.streetName`}
                />
              </GridItem>
              <GridItem colSpan={3}>
                <FormControl>
                  <FormLabel
                    htmlFor={`providers.${index}.address.secondary`}
                    fontSize="md"
                    mb="0px"
                    fontWeight="normal"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Address Line 2
                  </FormLabel>
                  <Input
                    id={`providers.${index}.address.secondary`}
                    type="text"
                    {...register(`providers.${index}.address.secondary`)}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={3}>
                <Controller
                  name={`providers.${index}.address.city`}
                  rules={{ required: "City is required" }}
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl isInvalid={!!(fieldState.error && fieldState.error.message)}>
                      <FormLabel
                        htmlFor={`providers.${index}.address.city`}
                        fontSize="md"
                        mb="0px"
                        fontWeight="normal"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        City *
                      </FormLabel>
                      <Input
                        id={`providers.${index}.address.city`}
                        type="text"
                        {...field}
                        value={field?.value || ""}
                      />
                      <FormErrorMessage>
                        <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                        {fieldState.error && fieldState.error.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl isInvalid={!!e?.address?.state?.message}>
                  <FormLabel
                    htmlFor={`providers.${index}.address.state`}
                    fontSize="md"
                    mb="0px"
                    fontWeight="normal"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    State *
                  </FormLabel>
                  <Select
                    id={`providers.${index}.address.state`}
                    size="md"
                    m="0px"
                    p="0px"
                    {...register(`providers.${index}.address.state`, {
                      required: "State is required",
                    })}
                    onChange={
                      onChangeSelect || register(`providers.${index}.address.state`).onChange
                    }
                  >
                    <option></option>
                    {STATES.map((state) => (
                      <option key={state.abbreviation} value={state.abbreviation}>
                        {state.abbreviation}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                    {e?.address?.state?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={!!e?.address?.zip?.message}>
                  <FormLabel
                    htmlFor={`providers.${index}.address.zip`}
                    fontSize="md"
                    mb="0px"
                    fontWeight="normal"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Zip Code *
                  </FormLabel>
                  <Input
                    id={`providers.${index}.address.zip`}
                    type="text"
                    {...register(`providers.${index}.address.zip`, {
                      required: "Zip code is required",
                    })}
                  />
                  <FormErrorMessage>
                    <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                    {e?.address?.zip?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          )}
        </Box>
      </Flex>
      <Flex mt="6px" gap="12px">
        <FormControl mt="16px" isInvalid={!!e?.limit?.message}>
          <FormLabel
            htmlFor={`providers.${index}.limit`}
            fontSize="md"
            mb="0px"
            fontWeight="normal"
          >
            Limit *
          </FormLabel>
          <Select
            id={`providers.${index}.limit`}
            size="md"
            m="0px"
            p="0px"
            {...register(`providers.${index}.limit`, {
              required: "Limit is required",
            })}
            onChange={onChangeSelect}
          >
            {Object.values(Limit).map((limit) => {
              return (
                <option key={limit} value={limit}>
                  {formatLimit(limit)}
                </option>
              );
            })}
          </Select>
          <FormErrorMessage>{e?.limit?.message}</FormErrorMessage>
        </FormControl>
        <FormControl mt="16px" isInvalid={!!e?.limitType?.message}>
          <FormLabel
            htmlFor={`providers.${index}.limitType`}
            fontSize="md"
            mb="0px"
            fontWeight="normal"
          >
            Limit Type *
          </FormLabel>
          <Select
            id={`providers.${index}.limitType`}
            size="md"
            m="0px"
            p="0px"
            {...register(`providers.${index}.limitType`, {
              required: "Limit type is required",
            })}
            onChange={onChangeSelect}
          >
            {Object.values(LimitType).map((limitType) => {
              return (
                <option key={limitType} value={limitType}>
                  {limitType}
                </option>
              );
            })}
          </Select>
          <FormErrorMessage>{e?.limitType?.message}</FormErrorMessage>
        </FormControl>
        <Controller
          rules={{ required: "Retro date is required" }}
          render={({ fieldState, field }) => (
            <FormControl mt="16px" isInvalid={Boolean(fieldState.error)}>
              <FormLabel
                htmlFor={`providers.${index}.retroDate`}
                fontSize="md"
                mb="0px"
                fontWeight="normal"
              >
                Retro Date *
              </FormLabel>
              <DatePicker
                id={`providers.${index}.retroDate`}
                invalid={Boolean(fieldState.error)}
                selected={field?.value || ""}
                onChange={(date: string) => {
                  setValue?.(`providers.${index}.retroDate`, date);
                }}
              />
              <FormErrorMessage>{fieldState.error && fieldState.error.message}</FormErrorMessage>
            </FormControl>
          )}
          control={control}
          name={`providers.${index}.retroDate`}
        />
      </Flex>

      <Box mt="16px">
        <Controller
          name={`providers.${index}.cyberOptOut`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              isChecked={!!value}
              value={`${value}`}
              onChange={(e) => {
                const bValue = e.target.value === "true" ? true : false;
                onChange(!bValue);
              }}
              key={index}
            >
              Opt out of cyber
            </Checkbox>
          )}
        />
      </Box>
    </>
  );
}
