import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, ButtonGroup, Text, Container, Image, Flex, Button } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { SAVE_SUBMISSION } from "mutations";
import { GET_SUBMISSION } from "queries";
import { generateId } from "utils";
import { Provider } from "__generated__/graphql";
import { useModelRatingApi } from "hooks";
import { DUMMY_PRODUCER, DUMMY_QUOTE_PROPERTIES, DUMMY_SUBMISSION, DUMMY_PROVIDER } from "mocks";

export function NewInsight() {
  const navigate = useNavigate();
  const { fetchModelRating } = useModelRatingApi();
  const [mutateFunction] = useMutation(SAVE_SUBMISSION, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [loading, setLoading] = useState(false);
  const env = process.env.REACT_APP_ENV || "dev";
  return (
    <Container>
      <Flex pt="100px" px="100px" justifyContent="center">
        <Image
          h="100px"
          src="https://uploads-ssl.webflow.com/645029572ba4d37613789f79/645029572ba4d36e42789f80_230426_Indigo_Logo_Primary-p-1080.png"
          alt="Indigo Logo"
        />
      </Flex>
      <Flex pt="0px" px="100px" justifyContent="center">
        <Text fontSize="6xl">Insight Tool</Text>
      </Flex>
      <Flex p="100px" justifyContent="center">
        {env !== "main" && (
          <ButtonGroup flexDirection="column">
            <Button
              onClick={async () => {
                navigate("/broker");
              }}
              colorScheme="indigo"
              isLoading={loading}
              variant="outline"
              data-cy="create-broker-submission-button"
              data-testid="create-broker-submission-button"
            >
              Begin Broker Submission
            </Button>
            <Divider my="20px" />
            <Button
              onClick={async () => {
                setLoading(true);
                const id = generateId();
                await mutateFunction({
                  variables: {
                    input: {
                      id,
                    },
                  },
                });
                navigate(`/insights/${id}`);
              }}
              colorScheme="indigo"
              isLoading={loading}
              variant="outline"
              data-cy="create-blank-submission-button"
              data-testid="create-blank-submission-button"
            >
              Create Blank Submission
            </Button>
            <Button
              onClick={async () => {
                setLoading(true);
                const id = generateId();
                await mutateFunction({
                  variables: {
                    input: {
                      id,
                      quoteProperties: DUMMY_QUOTE_PROPERTIES,
                      producer: DUMMY_PRODUCER,
                      providers: [
                        {
                          id: generateId(),

                          limitType: "Separate",
                          limit: "_1M_3M",
                          retroDate: "04/24/2001",
                        },
                      ],
                    },
                  },
                });
                navigate(`/insights/${id}`);
              }}
              colorScheme="indigo"
              isLoading={loading}
              data-cy="create-demo-submission-button"
              data-testid="create-demo-submission-button"
            >
              Create Demo Submission
            </Button>
            <Button
              onClick={async () => {
                setLoading(true);
                const id = generateId();
                const providerId = generateId();
                DUMMY_PROVIDER.id = providerId;
                await mutateFunction({
                  variables: {
                    input: {
                      ...DUMMY_SUBMISSION,
                      id,
                    },
                  },
                });
                await fetchModelRating(DUMMY_PROVIDER as Provider, id);
                navigate(`/insights/${id}/model-insights`);
              }}
              colorScheme="indigo"
              isLoading={loading}
              variant="outline"
              data-cy="create-dummy-submission-button"
            >
              Create Full Submission
            </Button>
          </ButtonGroup>
        )}
      </Flex>
    </Container>
  );
}
