import { AppetiteContext } from "./AppetiteContext";
import { useContext, useState, useEffect, useMemo, ReactNode } from "react";
import { ApiClientContext } from "providers"; // Import your API client context
import { Submission } from "__generated__/graphql";
import { EvaluationResult } from "localTypes";

interface AppetiteProviderProps {
  children: ReactNode;
  submission: Submission;
}

export function AppetiteProvider({ submission, children }: AppetiteProviderProps) {
  const [evaluationResult, setEvaluationResult] = useState<EvaluationResult | null>(null);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const apiClient = useContext(ApiClientContext); // Access the API client

  useEffect(() => {
    const fetchEvaluationResult = async () => {
      if (apiClient) {
        setIsLoading(true); // Set loading state to true
        try {
          if (submission) {
            const result = (await apiClient.appetiteEngine(submission)) as EvaluationResult; // Call the API
            setEvaluationResult(result || null); // Update state with the result
          }
        } catch (error) {
          console.error(error);
          setEvaluationResult(null); // Handle error appropriately
        } finally {
          setIsLoading(false); // Set loading state to false
        }
      }
    };

    fetchEvaluationResult(); // Invoke the fetch function
  }, [submission, apiClient]); // Dependencies

  const value = useMemo(
    () => ({
      evaluationResult,
      isLoadingToggle: isLoading, // Pass loading state as isLoadingToggle
    }),
    [evaluationResult, isLoading],
  );

  return <AppetiteContext.Provider value={value}>{children}</AppetiteContext.Provider>;
}

export const useAppetite = () => {
  const context = useContext(AppetiteContext);
  if (context === undefined) {
    throw new Error("useAppetite must be used within an AppetiteProvider");
  }
  return context.evaluationResult;
};
