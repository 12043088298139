import { SearchPollingContext } from "./SearchPollingContext";
import { useContext, useState, useEffect, useMemo, ReactNode } from "react";

interface SearchPollingProviderProps {
  children: ReactNode;
  isPollingSearchEvents: boolean;
  pollingProviderIds: string[];
}
export function SearchPollingProvider({
  isPollingSearchEvents,
  pollingProviderIds,
  children,
}: SearchPollingProviderProps) {
  const [isPolling, setIsPolling] = useState<boolean>(isPollingSearchEvents);

  useEffect(() => {
    setIsPolling(isPollingSearchEvents);
  }, [isPollingSearchEvents]);

  const value = useMemo(
    () => ({ isPollingSearchEvents: isPolling, pollingProviderIds }),
    [pollingProviderIds, isPolling],
  );

  return <SearchPollingContext.Provider value={value}>{children}</SearchPollingContext.Provider>;
}

export const useIsPollingSearchResults = () => {
  const context = useContext(SearchPollingContext);
  if (context === undefined) {
    throw new Error("useIsPollingSearchresults must be used within a SearchPollingProvider");
  }
  return {
    isPollingSearchEvents: context.isPollingSearchEvents,
    pollingProviderIds: context.pollingProviderIds,
  };
};
