import { useRef, useCallback, useEffect, ChangeEvent, useState } from "react";
import { useSmartyStreets } from "hooks";
import CreatableSelect from "react-select/creatable";
import { AddressInput } from "__generated__/graphql";
import { debounce } from "lodash";
import "./styles.css";

export type AddressOption = {
  label: string;
  value?: AddressInput;
};
interface AddressInputFieldProps {
  value: AddressOption | null;
  onChange: (value: AddressOption | null) => void;
  id?: string;
  isInvalid?: boolean;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function AddressInputField({
  onBlur,
  value,
  onChange,
  id,
  isInvalid,
}: AddressInputFieldProps) {
  const [options, setOptions] = useState<AddressOption[]>([]);
  const [localInputValue, setLocalInputValue] = useState(value?.value?.streetName || "");
  const { autocomplete, details } = useSmartyStreets();

  const loadSuggestions = useCallback(
    async (inputValue: string): Promise<Array<AddressOption>> => {
      if (inputValue.length < 3) {
        return [];
      }
      try {
        const data = await autocomplete(inputValue);
        const options = data.suggestions.map(({ street_line, city, state, zipcode }) => {
          const value = `${street_line}, ${city}, ${state} ${zipcode}`;
          return {
            label: value,
            value: {
              streetName: street_line,
              city,
              state,
              zipcode,
            },
          };
        });
        setOptions(options);
        setLocalInputValue(inputValue);
        return options;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    [autocomplete],
  );

  const debouncedLoadSuggestions = useRef(debounce(loadSuggestions, 500)).current;

  useEffect(() => {
    return () => {
      debouncedLoadSuggestions.cancel();
    };
  }, [debouncedLoadSuggestions]);
  async function handleChangeAddress(createOptionValue: string) {
    if (!createOptionValue) {
      onChange(null);
      return;
    }
    try {
      const streetName = createOptionValue;

      onChange({
        label: createOptionValue,
        value: {
          streetName,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <CreatableSelect
      onBlur={onBlur}
      aria-label={id}
      name={id}
      inputId={id}
      inputValue={localInputValue}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      onInputChange={(inputValue, action) => {
        if (action.action === "input-change") {
          debouncedLoadSuggestions(inputValue);
          setLocalInputValue(inputValue);
        }
      }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          boxShadow: "none",
          border: state.isFocused
            ? "2px solid #2c0b9b"
            : isInvalid
              ? "2px solid #FF4D00"
              : "1px solid #8D89D4",
          borderRadius: "3px",
          opacity: "1 !important",
          height: "40px",
          "&:hover": {
            border: state.isFocused
              ? "2px solid #2c0b9b"
              : isInvalid
                ? "2px solid #FF4D00"
                : "1px solid #2c0b9b",
          },
          width: "100%",
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          padding: "0",
          opacity: "1 !important",
        }),
      }}
      onChange={async (option) => {
        if (!option || !option.value) {
          onChange(null);
          return;
        }
        setLocalInputValue(option.value.streetName || option.label);
        const { streetName, city, state } = option.value;
        const addressDetails = await details(streetName || "", city || "", state || "");
        onChange({
          label: `${streetName}, ${city}, ${state} ${addressDetails?.postalCode}`,
          value: {
            streetName,
            city,
            state,
            zip: addressDetails?.postalCode,
            countyFips: addressDetails?.countyFips,
            countyName: addressDetails?.countyName,
          },
        });
      }}
      onCreateOption={handleChangeAddress}
      options={options}
      filterOption={() => true}
    />
  );
}
